import {
  Button,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { ReactChild } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  button: {
    display: "flex",
    width: "100%",
    backgroundColor: 'rgba(21, 61, 111, 0.44)',
    color: '#00D6BF',
    borderRadius: 20,
    outline: 'none',
    border: '1px solid transparent',
    paddingLeft: 42,
    paddingRight: 42,
    paddingTop: 16,
    paddingBottom: 16,
    margin: `${theme.spacing(2)}px auto`,
    '& .MuiButton-label': {
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'none',
      fontFamily: 'unset',
      lineHeight: 'normal'
    },
    '&:hover': {
      backgroundColor: 'rgba(21, 61, 111, 0.44)'
    }
  },
  loader: {
    position: "absolute",
    top: 18,
    right: 20,
    color: theme.palette.secondary.light,
  },
}));

export default function ButtonWithLoader({
  disabled,
  onClick,
  showLoader,
  error,
  children,
}: {
  disabled?: boolean;
  onClick: () => void;
  showLoader?: boolean;
  error?: string;
  children: ReactChild;
}) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={disabled}
          onClick={onClick}
        >
          {error || children}
        </Button>
        {showLoader ? (
          <CircularProgress
            size={16}
            color="inherit"
            className={classes.loader}
          />
        ) : null}
      </div>
    </>
  );
}
