import { Contract } from '@ethersproject/contracts'
import ENS_PUBLIC_RESOLVER_ABI from '../abis/ens-public-resolver.json'
import UniswapInterfaceMulticallJson from '@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json'
import ENS_ABI from '../abis/ens-registrar.json'
import {
  MULTICALL_ADDRESS,
  ENS_REGISTRAR_ADDRESSES,
} from '../constants/addresses'
import useActiveWeb3React from './useActiveWeb3React'
import { useMemo } from 'react'

import { getContract } from '../utils'

const { abi: MulticallABI } = UniswapInterfaceMulticallJson
// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { library, account, chainId } = useActiveWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !library || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, library, chainId, withSignerIfPossible, account]) as T
}

export function useInterfaceMulticall() {
  return useContract<any>(MULTICALL_ADDRESS, MulticallABI, false) as any
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract<any>(ENS_REGISTRAR_ADDRESSES, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean) {
  return useContract<any>(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}
