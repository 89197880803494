import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MarketsMap } from "../hooks/useMarketsMap";
import { RelayerTokenInfo } from "../hooks/useRelayersAvailable";
import {
  DataWrapper,
  errorDataWrapper,
  fetchDataWrapper,
  getEmptyDataWrapper,
  receiveDataWrapper,
} from "./helpers";

export interface TokenMetadataState {
  marketsMap: DataWrapper<MarketsMap>;
  relayerTokenInfo: DataWrapper<RelayerTokenInfo>;
}

const initialState: TokenMetadataState = {
  marketsMap: getEmptyDataWrapper(),
  relayerTokenInfo: getEmptyDataWrapper(),
};

export const tokenSlice = createSlice({
  name: "tokenInfos",
  initialState,
  reducers: {
    receiveMarketsMap: (state, action: PayloadAction<MarketsMap>) => {
      state.marketsMap = receiveDataWrapper(action.payload);
    },
    fetchMarketsMap: (state) => {
      state.marketsMap = fetchDataWrapper();
    },
    errorMarketsMap: (state, action: PayloadAction<string>) => {
      state.marketsMap = errorDataWrapper(action.payload);
    },

    receiveRelayerTokenInfo: (
      state,
      action: PayloadAction<RelayerTokenInfo>
    ) => {
      state.relayerTokenInfo = receiveDataWrapper(action.payload);
    },
    fetchRelayerTokenInfo: (state) => {
      state.relayerTokenInfo = fetchDataWrapper();
    },
    errorRelayerTokenInfo: (state, action: PayloadAction<string>) => {
      state.relayerTokenInfo = errorDataWrapper(action.payload);
    },

    reset: () => initialState,
  },
});

export const {
  receiveMarketsMap,
  fetchMarketsMap,
  errorMarketsMap,
  receiveRelayerTokenInfo,
  fetchRelayerTokenInfo,
  errorRelayerTokenInfo,
  reset,
} = tokenSlice.actions;

export default tokenSlice.reducer;
