import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    width: "100%",
    backgroundColor: 'rgba(21, 61, 111, 0.44)',
    color: '#00D6BF',
    borderRadius: 20,
    outline: 'none',
    fontWeight: 500,
    border: '1px solid transparent',
    margin: `${theme.spacing(2)}px auto`,
    padding: 16,
    '& .MuiButton-label': {
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'none',
      fontFamily: 'unset',
      lineHeight: 'normal'
    },
    '&:hover': {
      backgroundColor: 'rgba(21, 61, 111, 0.44)'
    }
  },
  icon: {
    height: 24,
    width: 24,
  },
}));

const ToggleConnectedButton = ({
  connect,
  disconnect,
  // connected,
  pk,
  walletIcon,
}: {
  connect(): any;
  disconnect(): any;
  // connected: boolean;
  pk: string;
  walletIcon?: string;
}) => {
  const classes = useStyles();
  const is0x = pk.startsWith("0x");
  // return connected ? (
  //   <Tooltip title={pk}>
  //     <Button
  //       color="primary"
  //       variant="contained"
  //       size="small"
  //       onClick={disconnect}
  //       className={classes.button}
  //     >
  //       Disconnect
  //     </Button>
  //   </Tooltip>
  // ) : (
  //   <Button
  //     onClick={connect}
  //     className={classes.button}
  //   >
  //     Connect Wallet
  //   </Button>
  // );
  return (
    <Button
      onClick={connect}
      className={classes.button}
    >
      Connect Wallet
    </Button>
  )
};

export default ToggleConnectedButton;
