import {
  approveEth,
  ChainId,
  CHAIN_ID_KLAYTN,
  getAllowanceEth,
  isEVMChain,
} from '@certusone/wormhole-sdk'
import { BigNumber } from 'ethers'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEthereumProvider } from '../contexts/EthereumProviderContext'
import { selectTransferIsApproving } from '../store/selectors'
import { setIsApproving } from '../store/transferSlice'
import { getTokenBridgeAddressForChain } from '../utils/consts'

let timer: any = null
export default function useAllowance(
  chainId: ChainId,
  tokenAddress?: string,
  transferAmount?: BigInt,
  sourceIsNative?: boolean
) {
  const dispatch = useDispatch()
  const [allowance, setAllowance] = useState<BigInt | null>(null)
  const [isAllowanceFetching, setIsAllowanceFetching] = useState(true)
  const isApproveProcessing = useSelector(selectTransferIsApproving)
  const { signer } = useEthereumProvider()
  const sufficientAllowance =
    !isEVMChain(chainId) ||
    sourceIsNative ||
    (allowance && transferAmount && allowance >= transferAmount)

  useEffect(() => {
    const getAllowance = () => {
      if (isEVMChain(chainId) && tokenAddress && signer && !isApproveProcessing) {
        getAllowanceEth(
          getTokenBridgeAddressForChain(chainId),
          tokenAddress,
          signer
        ).then(
          (result) => {
            setIsAllowanceFetching(false)
            setAllowance(result.toBigInt())
          },
          (error) => {
          }
        )
      }
    }
    if (timer) {
      clearInterval(timer)
    }
    getAllowance()
    timer = setInterval(() => {
      getAllowance()
    }, 2000)
    return () => clearInterval(timer)
  }, [chainId, tokenAddress, signer, isApproveProcessing])

  const approveAmount: (amount: BigInt) => Promise<any> = useMemo(() => {
    return !isEVMChain(chainId) || !tokenAddress || !signer
      ? (amount: BigInt) => {
          return Promise.resolve()
        }
      : (amount: BigInt) => {
          dispatch(setIsApproving(true))
          // Klaytn requires specifying gasPrice
          const gasPricePromise =
            chainId === CHAIN_ID_KLAYTN
              ? signer.getGasPrice()
              : Promise.resolve(undefined)
          return gasPricePromise.then(
            (gasPrice) =>
              approveEth(
                getTokenBridgeAddressForChain(chainId),
                tokenAddress,
                signer,
                BigNumber.from(amount),
                gasPrice === undefined ? {} : { gasPrice }
              ).then(
                () => {
                  dispatch(setIsApproving(false))
                  return Promise.resolve()
                },
                () => {
                  dispatch(setIsApproving(false))
                  return Promise.reject()
                }
              ),
            () => {
              dispatch(setIsApproving(false))
              return Promise.reject()
            }
          )
        }
  }, [chainId, tokenAddress, signer, dispatch])

  return useMemo(
    () => ({
      sufficientAllowance,
      approveAmount,
      isAllowanceFetching,
      isApproveProcessing,
    }),
    [
      sufficientAllowance,
      approveAmount,
      isAllowanceFetching,
      isApproveProcessing,
    ]
  )
}
