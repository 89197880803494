import {
  ChainId,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_ETHEREUM_ROPSTEN,
} from '@certusone/wormhole-sdk'
import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { ReactChild } from 'react'
import { ExternalLink as LinkIcon } from 'react-feather'
import styled from 'styled-components/macro'
import useCopyToClipboard from '../hooks/useCopyToClipboard'
import { ParsedTokenAccount } from '../store/transferSlice'
import { CLUSTER, getExplorerName } from '../utils/consts'
import { ExternalLink, LinkStyledButton, ThemedText } from '../theme'
import Copy from './AccountDetails/Copy'

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  display: flex;
  align-items: center;
  font-size: 0.825rem;
  color: #8F96AC;
  margin-left: 1rem;
  :hover {
    color: #C3C5CB;
  }
`
const useStyles = makeStyles((theme) => ({
  mainTypog: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textDecoration: 'underline',
    textUnderlineOffset: '2px',
  },
  noGutter: {
    marginLeft: 0,
    marginRight: 0,
  },
  noUnderline: {
    textDecoration: 'none',
  },
  buttons: {
    marginLeft: '.5rem',
    marginRight: '.5rem',
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 13,
    color: '#8F96AC',
    marginLeft: '.5rem',
    marginRight: '.5rem',
  },
  linkIcon: {
    marginRight: 5,
  }
}))

const tooltipStyles = {
  tooltip: {
    minWidth: 'max-content',
    textAlign: 'center',
    padding: 8,
    border: '1px solid rgb(44, 47, 54)',
    background: 'rgb(25, 27, 31)',
    borderRadius: 16,
    '& > *': {
      margin: '.25rem',
    },
    marginTop: 18,
    boxShadow: '0px 4px 6px 2px rgba(0 0 0 / 20%)'
  },
  arrow: {
    color: 'rgb(25, 27, 31)',
    width: 20,
    height: 16,
    zIndex: -1,
    '&::before': {      
      border: '1px solid rgb(44, 47, 54)',
    },
    marginTop: "-15px!important"
  }
}

// @ts-ignore
const StyledTooltip = withStyles(tooltipStyles)(Tooltip)

export default function SmartAddress({
  chainId,
  parsedTokenAccount,
  address,
  symbol,
  tokenName,
  variant,
  noGutter,
  noUnderline,
  extraContent,
  isAsset,
}: {
  chainId: ChainId
  parsedTokenAccount?: ParsedTokenAccount
  address?: string
  logo?: string
  tokenName?: string
  symbol?: string
  variant?: any
  noGutter?: boolean
  noUnderline?: boolean
  extraContent?: ReactChild
  isAsset?: boolean
}) {
  const classes = useStyles()
  const useableAddress = parsedTokenAccount?.mintKey || address || ''
  const useableSymbol = parsedTokenAccount?.symbol || symbol || ''
  // const useableLogo = logo || isNativeTerra ? getNativeTerraIcon(useableSymbol) : null
  const isNative = parsedTokenAccount?.isNativeAsset || false
  const addressShort = ''

  const useableName = isNative
    ? 'Native Currency'
    : parsedTokenAccount?.name
    ? parsedTokenAccount.name
    : tokenName
    ? tokenName
    : ''
  const explorerAddress = isNative
    ? null
    : chainId === CHAIN_ID_ETH
    ? `https://${CLUSTER === 'testnet' ? 'goerli.' : ''}etherscan.io/${
        isAsset ? 'token' : 'address'
      }/${useableAddress}`
    : chainId === CHAIN_ID_ETHEREUM_ROPSTEN
    ? `https://${CLUSTER === 'testnet' ? 'ropsten.' : ''}etherscan.io/${
        isAsset ? 'token' : 'address'
      }/${useableAddress}`
    : chainId === CHAIN_ID_BSC
    ? `https://${CLUSTER === 'testnet' ? 'testnet.' : ''}bscscan.com/${
        isAsset ? 'token' : 'address'
      }/${useableAddress}`
    : undefined
  const explorerName = getExplorerName(chainId)

  const copyToClipboard = useCopyToClipboard(useableAddress)

  const explorerButton = !explorerAddress ? null : (
    <AddressLink
      hasENS={!!explorerAddress}
      isENS={true}
      href={explorerAddress}
    >
      <LinkIcon size={16} />
      <span style={{ marginLeft: '4px' }}>
        View on Explorer
      </span>
    </AddressLink>
  )
  //TODO add icon here
  const copyButton = isNative ? null : (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Copy toCopy={useableAddress}>
      <span style={{ marginLeft: '4px' }}>
        Copy Address
      </span>
    </Copy>
  )

  const tooltipContent = (
    <>
      {useableName && <Typography>{useableName}</Typography>}
      {useableSymbol && !isNative && (
        <Typography noWrap variant="body2">
          {addressShort}
        </Typography>
      )}
      <AccountControl>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {copyButton}
          {explorerButton}
        </div>
      </AccountControl>
      {extraContent ? extraContent : null}
    </>
  )

  return (
    <StyledTooltip
      title={tooltipContent}
      interactive={true}
      className={classes.mainTypog}
      arrow
    >
      <Typography
        variant={variant || 'body1'}
        className={clsx(classes.mainTypog, {
          [classes.noGutter]: noGutter,
          [classes.noUnderline]: noUnderline,
        })}
        component="div"
      >
        {useableSymbol || addressShort}
      </Typography>
    </StyledTooltip>
  )
}
