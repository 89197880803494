import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core'
import { NetworkContextName } from './constants/misc'
import App from './App'
import ApplicationUpdater from './state/application/updater'
import { BlockUpdater } from './hooks/useBlockNumber'
import { MulticallUpdater } from './state/multicall'
import { BetaContextProvider } from './contexts/BetaContext'
import { EthereumProviderProvider } from './contexts/EthereumProviderContext'
import ErrorBoundary from './ErrorBoundary'
import { theme } from './muiTheme'
import { store } from './store'
import getLibrary from './utils/getLibrary'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <SnackbarProvider maxSnack={3}>
            <BetaContextProvider>
              <EthereumProviderProvider>
                <HashRouter>
                  <Web3ReactProvider getLibrary={getLibrary}>
                    <Web3ProviderNetwork getLibrary={getLibrary}>
                      <>
                        <ApplicationUpdater />
                        <BlockUpdater />
                        <MulticallUpdater />
                      </>
                      <App />
                    </Web3ProviderNetwork>
                  </Web3ReactProvider>
                </HashRouter>
              </EthereumProviderProvider>
            </BetaContextProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
)
