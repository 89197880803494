// eslint-disable-next-line no-restricted-imports
import { darken } from 'polished'
import { useMemo } from 'react'
import { Activity } from 'react-feather'
import styled, { css } from 'styled-components/macro'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'

// import useENSName from '../../hooks/useENSName'
// import { useHasSocks } from '../../hooks/useSocksBalance'
import { useWalletModalToggle } from '../../state/application/hooks'
import { useAppSelector } from '../../state/hooks'
import { RootState } from '../../store'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import StatusIcon from '../Identicon/StatusIcon'
// import Loader from '../Loader'
// import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const Web3StatusGeneric = styled(ButtonSecondary)`
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 14px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 1px;
  :focus {
    outline: none;
  }
`
const Web3StatusError = styled(Web3StatusGeneric)`
  width: auto;
  background-color: #ff4343;
  border: 1px solid #ff4343;
  color: #ffffff;
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${darken(0.1, '#FF4343')};
  }
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  width: 150px;
  background-color: #376bad70;
  border: none;

  color: #00d6bf;
  font-weight: 500;

  :hover,
  :focus {
    border: 1px solid ${darken(0.05, '#376bad70')};
    color: #00d6bf;
  }

  ${({ faded }) =>
    faded &&
    css`
      background-color: #153d6f70;
      border: 1px solid #153d6f70;
      color: #00d6bf;

      :hover,
      :focus {
        border: 1px solid ${darken(0.05, '#376bad70')};
        color: ${darken(0.05, '#00D6BF')};
      }
    `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  width: 150px;
  background-color: ${({ pending }) => (pending ? '#00BFAA' : '#212429')};
  border: 1px solid ${({ pending }) => (pending ? '#00BFAA' : '#212429')};
  color: ${({ pending }) => (pending ? '#FFFFFF' : '#FFFFFF')};
  font-weight: 500;
  :hover,
  :focus {
    border: 1px solid ${darken(0.05, '#40444F')};

    :focus {
      border: 1px solid
        ${({ pending }) =>
          pending ? darken(0.1, '#00BFAA') : darken(0.1, '#2C2F36')};
    }
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: fit-content;
  font-weight: 500;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`
function WrappedStatusIcon({
  connector,
}: {
  connector: AbstractConnector
}) {
  return (
    <IconWrapper size={16}>
      <StatusIcon connector={connector} />
    </IconWrapper>
  )
}

function Web3StatusInner() {
  const { account, connector, error }: any = useWeb3React()

  // const { ENSName } = useENSName(account ?? undefined)
  // const hasSocks = useHasSocks()
  const toggleWalletModal = useWalletModalToggle()

  if (account) {
    return (
      <Web3StatusConnected
        id="web3-status-connected"
        onClick={toggleWalletModal}
      >
        {/* {hasPendingTransactions ? (
          <RowBetween>
            <Text>
              {pending?.length} Pending
            </Text>{' '}
            <Loader stroke="white" />
          </RowBetween>
        ) : (
          <>
            {hasSocks ? <Sock /> : null}
            <Text>{ENSName || shortenAddress(account)}</Text>
          </>
        )} */}
        <>
          <Text>{shortenAddress(account)}</Text>
        </>
        {connector && <WrappedStatusIcon connector={connector} />}
      </Web3StatusConnected>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>
          {error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}
        </Text>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnect
        id="connect-wallet"
        onClick={toggleWalletModal}
        faded={!account}
      >
        <Text>Connect Wallet</Text>
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  const { active, account } = useWeb3React()
  const openModal = useAppSelector(
    (state: RootState) => state.application.openModal
  )
  const ENSName = undefined // const { ENSName } = useENSName(account ?? undefined)
  return (
    <>
      <Web3StatusInner />
      {(active || openModal === 0) && (
        <WalletModal ENSName={ENSName ?? undefined} />
      )}
    </>
  )
}
