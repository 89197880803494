import { darken } from 'polished'
import { Check, ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps as ButtonPropsOriginal } from 'rebass/styled-components'
import styled from 'styled-components/macro'

import { RowBetween } from '../Row'

type ButtonProps = Omit<ButtonPropsOriginal, 'css'>

export const BaseButton = styled(RebassButton)<
  {
    padding?: string
    width?: string
    $borderRadius?: string
    altDisabledStyle?: boolean
  } & ButtonProps
>`
  padding: ${({ padding }) => padding ?? '16px'};
  width: ${({ width }) => width ?? '100%'};
  font-weight: 500;
  text-align: center;
  border-radius: ${({ $borderRadius }) => $borderRadius ?? '20px'};
  outline: none;
  border: 1px solid transparent;
  color: #FFFFFF;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    opacity: 50%;
    cursor: auto;
    pointer-events: none;
  }

  will-change: transform;
  transition: transform 450ms ease;
  transform: perspective(1px) translateZ(0);

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`

export const ButtonPrimary = styled(BaseButton)`
  background-color: #00BFAA;
  color: white;
  &:focus {
    box-shadow: 0 0 0 1pt ${darken(0.05, '#00BFAA')};
    background-color: ${darken(0.05, '#00BFAA')};
  }
  &:hover {
    background-color: ${darken(0.05, '#00BFAA')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.05, '#00BFAA')};
    background-color: ${darken(0.05, '#00BFAA')};
  }
  &:disabled {
    background-color: #00BFAA;
    color: white;
    cursor: auto;
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
`

export const ButtonLight = styled(BaseButton)`
  background-color: #153d6f70;
  color: #00D6BF;
  font-size: 16px;
  font-weight: 500;
  &:focus {
    box-shadow: 0 0 0 1pt ${darken(0.03, '#153d6f70')};
    background-color: ${darken(0.03, '#153d6f70')};
  }
  &:hover {
    background-color: ${darken(0.03, '#153d6f70')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.05, '#153d6f70')};
    background-color: ${darken(0.05, '#153d6f70')};
  }
  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: #153d6f70;
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonGray = styled(BaseButton)`
  background-color: #212429;
  color: #C3C5CB;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background-color: ${darken(0.05, '#2C2F36')};
  }
  &:active {
    background-color: ${darken(0.1, '#2C2F36')};
  }
`

export const ButtonSecondary = styled(BaseButton)`
  border: 1px solid #376bad70;
  color: #00BFAA;
  background-color: transparent;
  font-size: 16px;
  border-radius: 12px;
  padding: ${({ padding }) => (padding ? padding : '10px')};

  &:focus {
    box-shadow: 0 0 0 1pt #376bad70;
    border: 1px solid #4D8FEA;
  }
  &:hover {
    border: 1px solid #4D8FEA;
  }
  &:active {
    box-shadow: 0 0 0 1pt #376bad70;
    border: 1px solid #4D8FEA;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`

export const ButtonOutlined = styled(BaseButton)`
  border: 1px solid #2C2F36;
  background-color: transparent;
  color: #FFFFFF;
  &:focus {
    box-shadow: 0 0 0 1px #565A69;
  }
  &:hover {
    box-shadow: 0 0 0 1px #565A69;
  }
  &:active {
    box-shadow: 0 0 0 1px #565A69;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonYellow = styled(BaseButton)`
  background-color: #F3B71E;
  color: white;
  &:focus {
    box-shadow: 0 0 0 1pt ${darken(0.05, '#2C2F36')};
    background-color: ${darken(0.05, '#2C2F36')};
  }
  &:hover {
    background-color: ${darken(0.05, '#2C2F36')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#2C2F36')};
    background-color: ${darken(0.1, '#2C2F36')};
  }
  &:disabled {
    background-color: #F3B71E;
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(BaseButton)`
  background-color: transparent;
  color: #00BFAA;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: none;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

export const ButtonText = styled(BaseButton)`
  padding: 0;
  width: fit-content;
  background: none;
  text-decoration: none;
  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    text-decoration: underline;
  }
  &:hover {
    // text-decoration: underline;
    opacity: 0.9;
  }
  &:active {
    text-decoration: underline;
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
  }
`

const ButtonConfirmedStyle = styled(BaseButton)`
  background-color: #40444F;
  color: #FFFFFF;
  /* border: 1px solid #27AE60; */

  &:disabled {
    opacity: 50%;
    background-color: #2C2F36;
    color: #C3C5CB;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(BaseButton)`
  background-color: #FF4343;
  border: 1px solid #FF4343;

  &:focus {
    box-shadow: 0 0 0 1pt ${darken(0.05, '#FF4343')};
    background-color: ${darken(0.05, '#FF4343')};
  }
  &:hover {
    background-color: ${darken(0.05, '#FF4343')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#FF4343')};
    background-color: ${darken(0.1, '#FF4343')};
  }
  &:disabled {
    opacity: 50%;
    cursor: auto;
    box-shadow: none;
    background-color: #FF4343;
    border: 1px solid #FF4343;
  }
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  )
}

const ActiveOutlined = styled(ButtonOutlined)`
  border: 1px solid;
  border-color: #00BFAA;
`

const Circle = styled.div`
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: #00BFAA;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CheckboxWrapper = styled.div`
  width: 20px;
  padding: 0 10px;
  position: absolute;
  top: 11px;
  right: 15px;
`

const ResponsiveCheck = styled(Check)`
  size: 13px;
`

export function ButtonRadioChecked({ active = false, children, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return (
      <ButtonOutlined $borderRadius="12px" padding="12px 8px" {...rest}>
        {<RowBetween>{children}</RowBetween>}
      </ButtonOutlined>
    )
  } else {
    return (
      <ActiveOutlined {...rest} padding="12px 8px" $borderRadius="12px">
        {
          <RowBetween>
            {children}
            <CheckboxWrapper>
              <Circle>
                <ResponsiveCheck size={13} stroke={'#fff'} />
              </Circle>
            </CheckboxWrapper>
          </RowBetween>
        }
      </ActiveOutlined>
    )
  }
}
