import {
  ChainId,
  CHAIN_ID_ALGORAND,
  CHAIN_ID_AURORA,
  CHAIN_ID_AVAX,
  CHAIN_ID_BSC,
  CHAIN_ID_CELO,
  CHAIN_ID_ETH,
  CHAIN_ID_ETHEREUM_ROPSTEN,
  CHAIN_ID_FANTOM,
  CHAIN_ID_KLAYTN,
  CHAIN_ID_KARURA,
  CHAIN_ID_OASIS,
  CHAIN_ID_POLYGON,
  CHAIN_ID_SOLANA,
  CHAIN_ID_TERRA,
} from "@certusone/wormhole-sdk";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { ExternalLink as LinkIcon } from 'react-feather'
import styled from 'styled-components/macro'
import { Transaction } from "../store/transferSlice";
import { CLUSTER, getExplorerName } from "../utils/consts";
import { ExternalLink } from '../theme'

const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0.825rem;
  color: #8F96AC;
  margin-top: 8px;
  :hover {
    color: #C3C5CB;
  }
`

const useStyles = makeStyles((theme) => ({
  tx: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  viewButton: {
    marginTop: theme.spacing(1),
  },
}));

export default function ShowTx({
  chainId,
  tx,
}: {
  chainId: ChainId;
  tx: Transaction;
}) {
  const classes = useStyles();
  const showExplorerLink =
    CLUSTER === "testnet" ||
    CLUSTER === "mainnet" ||
    (CLUSTER === "devnet" &&
      (chainId === CHAIN_ID_SOLANA || chainId === CHAIN_ID_TERRA));
  const explorerAddress =
    chainId === CHAIN_ID_ETH
      ? `https://${CLUSTER === "testnet" ? "goerli." : ""}etherscan.io/tx/${
          tx?.id
        }`
      : chainId === CHAIN_ID_ETHEREUM_ROPSTEN
      ? `https://${CLUSTER === "testnet" ? "ropsten." : ""}etherscan.io/tx/${
          tx?.id
        }`
      : chainId === CHAIN_ID_BSC
      ? `https://${CLUSTER === "testnet" ? "testnet." : ""}bscscan.com/tx/${
          tx?.id
        }`
      : chainId === CHAIN_ID_POLYGON
      ? `https://${CLUSTER === "testnet" ? "mumbai." : ""}polygonscan.com/tx/${
          tx?.id
        }`
      : chainId === CHAIN_ID_AVAX
      ? `https://${CLUSTER === "testnet" ? "testnet." : ""}snowtrace.io/tx/${
          tx?.id
        }`
      : chainId === CHAIN_ID_OASIS
      ? `https://${
          CLUSTER === "testnet" ? "testnet." : ""
        }explorer.emerald.oasis.dev/tx/${tx?.id}`
      : chainId === CHAIN_ID_AURORA
      ? `https://${CLUSTER === "testnet" ? "testnet." : ""}aurorascan.dev/tx/${
          tx?.id
        }`
      : chainId === CHAIN_ID_FANTOM
      ? `https://${CLUSTER === "testnet" ? "testnet." : ""}ftmscan.com/tx/${
          tx?.id
        }`
      : chainId === CHAIN_ID_KLAYTN
      ? `https://${CLUSTER === "testnet" ? "baobab." : ""}scope.klaytn.com/tx/${
          tx?.id
        }`
      : chainId === CHAIN_ID_CELO
      ? `https://${
          CLUSTER === "testnet"
            ? "alfajores-blockscout.celo-testnet.org"
            : "explorer.celo.org"
        }/tx/${tx?.id}`
      : chainId === CHAIN_ID_KARURA
      ? `https://${
          CLUSTER === "testnet"
            ? "blockscout.karura-dev.aca-dev.network"
            : "blockscout.karura.network"
        }/tx/${tx?.id}`
      : chainId === CHAIN_ID_SOLANA
      ? `https://explorer.solana.com/tx/${tx?.id}${
          CLUSTER === "testnet"
            ? "?cluster=devnet"
            : CLUSTER === "devnet"
            ? "?cluster=custom&customUrl=http%3A%2F%2Flocalhost%3A8899"
            : ""
        }`
      : chainId === CHAIN_ID_TERRA
      ? `https://finder.terra.money/${
          CLUSTER === "devnet"
            ? "localterra"
            : CLUSTER === "testnet"
            ? "bombay-12"
            : "columbus-5"
        }/tx/${tx?.id}`
      : chainId === CHAIN_ID_ALGORAND
      ? `https://${CLUSTER === "testnet" ? "testnet." : ""}algoexplorer.io/tx/${
          tx?.id
        }`
      : undefined;
  const explorerName = getExplorerName(chainId);

  return (
    <div className={classes.tx}>
      <Typography noWrap component="div" variant="body2">
        {tx.id}
      </Typography>
      {showExplorerLink && explorerAddress ? (
        <AddressLink
          hasENS={!!explorerAddress}
          isENS={true}
          href={explorerAddress}
        >
          <LinkIcon size={16} />
          <span style={{ marginLeft: '4px' }}>
            View on {explorerName}
          </span>
        </AddressLink>
      ) : null}
    </div>
  );
}
