import { Web3Provider } from '@ethersproject/providers'
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from '../constants/chains'
import { INFURA_NETWORK_URLS } from '../constants/infura'
import { InjectedConnector } from '@web3-react/injected-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { WalletConnectV2Connector } from './walletconnectV2';
import { WalletLinkConnector } from '@web3-react/walletlink-connector'

import UNISWAP_LOGO_URL from '../assets/svg/logo.svg'
import getLibrary from '../utils/getLibrary'
import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import { getRpcUrls } from "../utils/switchToNetwork"

const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const network = new NetworkConnector({
  urls: INFURA_NETWORK_URLS,
  defaultChainId: 1,
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? getLibrary(network.provider))
}

export const injected: any = new InjectedConnector({
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
})


export const walletconnectV2 = new WalletConnectV2Connector({
  supportedChainIds: [SupportedChainId.MAINNET],
  rpcMap: { [SupportedChainId.MAINNET]: getRpcUrls(SupportedChainId.MAINNET)[0] },
  chains: [SupportedChainId.MAINNET],
  qrcode: true,
})

// mainnet only
export const fortmatic: any = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1,
})

// mainnet only
export const portis: any = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1],
})

export const walletlink: any = new WalletLinkConnector({
  url: INFURA_NETWORK_URLS[SupportedChainId.MAINNET],
  appName: 'Uniswap',
  appLogoUrl: UNISWAP_LOGO_URL,
  supportedChainIds: ALL_SUPPORTED_CHAIN_IDS,
})
