import { isEVMChain } from "@certusone/wormhole-sdk";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles
} from "@material-ui/core";
import { ArrowDownward, CloseOutlined } from "@material-ui/icons";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectTransferOriginChain,
  selectTransferSourceChain,
  selectTransferSourceParsedTokenAccount,
} from "../../store/selectors";
import { CHAINS_BY_ID, MULTI_CHAIN_TOKENS } from "../../utils/consts";
import SmartAddress from "../SmartAddress";
import { useTargetInfo } from "./Target";
import TokenWarning from "./TokenWarning";

const useStyles = makeStyles((theme) => ({
  modalWrap: {
    '& .MuiDialog-paper' : {
      maxWidth: 420,
      maxHeight: '90vh',
      backgroundColor: 'rgb(25, 27, 31)',
      border: '1px solid rgb(33, 36, 41)',
      boxShadow: 'rgb(0 0 0 / 5%) 0px 4px 8px 0px',
      padding: '1rem',
      borderRadius: 20
    }
  },
  headerWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  confirmBtnWrap: {
    width: '100%',
    backgroundColor: 'rgba(21, 61, 111, 0.44)',
    color: '#00D6BF',
    borderRadius: 20,
    outline: 'none',
    padding: 16,
    fontWeight: 500,
    '& .MuiButton-label': {
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'none',
      fontFamily: 'unset',
      lineHeight: 'normal'
    },
    '&:hover': {
      backgroundColor: 'rgba(21, 61, 111, 0.44)'
    }
  }
}));

function SendConfirmationContent({
  open,
  onClose,
  onClick,
}: {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
}) {
  const classes = useStyles();

  const sourceChain = useSelector(selectTransferSourceChain);
  const sourceParsedTokenAccount = useSelector(
    selectTransferSourceParsedTokenAccount
  );
  const { targetChain, targetAsset, symbol, tokenName, logo } = useTargetInfo();
  const originChain = useSelector(selectTransferOriginChain);

  //TODO this check is essentially duplicated.
  const deservesTimeout = useMemo(() => {
    if (originChain && sourceParsedTokenAccount?.mintKey) {
      const searchableAddress = isEVMChain(originChain)
        ? sourceParsedTokenAccount.mintKey.toLowerCase()
        : sourceParsedTokenAccount.mintKey;
      return (
        originChain !== targetChain &&
        !!MULTI_CHAIN_TOKENS[sourceChain]?.[searchableAddress]
      );
    } else {
      return false;
    }
  }, [originChain, targetChain, sourceChain, sourceParsedTokenAccount]);
  const timeoutDuration = 5;

  const [countdown, setCountdown] = useState(
    deservesTimeout ? timeoutDuration : 0
  );

  useEffect(() => {
    if (!deservesTimeout || countdown === 0) {
      return;
    }
    let cancelled = false;

    setInterval(() => {
      if (!cancelled) {
        setCountdown((state) => state - 1);
      }
    }, 1000);

    return () => {
      cancelled = true;
    };
  }, [deservesTimeout, countdown]);

  useEffect(() => {
    if (open && deservesTimeout) {
      //Countdown starts on mount, but we actually want it to start on open
      setCountdown(timeoutDuration);
    }
  }, [open, deservesTimeout]);

  const sendConfirmationContent = (
    <>
      <div className={classes.headerWrap}>
        <div style={{
          fontSize: 16,
          fontWeight: 500
        }}>Confirm Transfer</div>
        <CloseOutlined style={{ width: 24, height: 24, color: 'white', cursor: 'pointer' }} fontSize="inherit" onClick={onClose} />
      </div>
      <DialogContent>
        {targetAsset ? (
          <div style={{ textAlign: "center", marginBottom: 16, marginTop: 16 }}>
            <Typography variant="subtitle1" style={{ marginBottom: 8 }}>
              You are about to perform this transfer:
            </Typography>
            <SmartAddress
              variant="h6"
              chainId={sourceChain}
              parsedTokenAccount={sourceParsedTokenAccount}
              isAsset
            />
            <div>
              <Typography variant="caption">
                {CHAINS_BY_ID[sourceChain].name}
              </Typography>
            </div>
            <div style={{ paddingTop: 4 }}>
              <ArrowDownward fontSize="inherit" />
            </div>
            <SmartAddress
              variant="h6"
              chainId={targetChain}
              address={targetAsset}
              symbol={symbol}
              tokenName={tokenName}
              logo={logo}
              isAsset
            />
            <div>
              <Typography variant="caption">
                {CHAINS_BY_ID[targetChain].name}
              </Typography>
            </div>
          </div>
        ) : null}
        <TokenWarning
          sourceAsset={sourceParsedTokenAccount?.mintKey}
          sourceChain={sourceChain}
          originChain={originChain}
          targetAsset={targetAsset ?? undefined}
          targetChain={targetChain}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.confirmBtnWrap}
          variant="contained"
          color="primary"
          onClick={onClick}
          size={"medium"}
          disabled={!!countdown}
        >
          {!!countdown ? countdown.toString() : "Confirm"}
        </Button>
      </DialogActions>
    </>
  );

  return sendConfirmationContent;
}

export default function SendConfirmationDialog({
  open,
  onClick,
  onClose,
}: {
  open: boolean;
  onClick: () => void;
  onClose: () => void;
}) {
  const classes = useStyles();

  return (
    <Dialog className={classes.modalWrap} open={open} onClose={onClose}>
      <SendConfirmationContent
        open={open}
        onClose={onClose}
        onClick={onClick}
      />
    </Dialog>
  );
}
