import {
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_ETHEREUM_ROPSTEN,
  isEVMChain,
} from '@certusone/wormhole-sdk'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Link,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useGetIsTransferCompleted from '../../hooks/useGetIsTransferCompleted'
import { useHandleRedeem } from '../../hooks/useHandleRedeem'
import useIsWalletReady from '../../hooks/useIsWalletReady'
import {
  selectTransferIsRecovery,
  selectTransferTargetAsset,
  selectTransferTargetChain,
  selectTransferUseRelayer,
} from '../../store/selectors'
import { reset, setStep } from '../../store/transferSlice'
import {
  getHowToAddTokensToWalletUrl,
  ROPSTEN_WETH_ADDRESS,
  WBNB_ADDRESS,
  WETH_ADDRESS,
} from '../../utils/consts'
import ButtonWithLoader from '../ButtonWithLoader'
import SmartAddress from '../SmartAddress'
import StepDescription from '../StepDescription'
import AddToMetamask from './AddToMetamask'
import RedeemPreview from './RedeemPreview'
import WaitingForWalletMessage from './WaitingForWalletMessage'

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  centered: {
    margin: theme.spacing(4, 0, 2),
    textAlign: 'center',
  },
}))

function Redeem() {
  const {
    handleClick,
    handleNativeClick,
    disabled,
    showLoader,
  } = useHandleRedeem()
  const useRelayer = useSelector(selectTransferUseRelayer)
  const [manualRedeem, setManualRedeem] = useState(!useRelayer)
  const handleManuallyRedeemClick = useCallback(() => {
    setManualRedeem(true)
  }, [])
  const handleSwitchToRelayViewClick = useCallback(() => {
    if (useRelayer) {
      setManualRedeem(false)
    }
  }, [useRelayer])
  const targetChain = useSelector(selectTransferTargetChain)

  const targetAsset = useSelector(selectTransferTargetAsset)
  const isRecovery = useSelector(selectTransferIsRecovery)
  const { isTransferCompletedLoading, isTransferCompleted } =
    useGetIsTransferCompleted(
      useRelayer ? false : true,
      useRelayer ? 5000 : undefined
    )
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isReady, statusMessage } = useIsWalletReady(targetChain)
  //TODO better check, probably involving a hook & the VAA
  const isEthNative =
    targetChain === CHAIN_ID_ETH &&
    targetAsset &&
    targetAsset.toLowerCase() === WETH_ADDRESS.toLowerCase()
  const isEthRopstenNative =
    targetChain === CHAIN_ID_ETHEREUM_ROPSTEN &&
    targetAsset &&
    targetAsset.toLowerCase() === ROPSTEN_WETH_ADDRESS.toLowerCase()
  const isBscNative =
    targetChain === CHAIN_ID_BSC &&
    targetAsset &&
    targetAsset.toLowerCase() === WBNB_ADDRESS.toLowerCase()
  const isNativeEligible = isEthNative || isEthRopstenNative || isBscNative
  const [useNativeRedeem, setUseNativeRedeem] = useState(true)
  const toggleNativeRedeem = useCallback(() => {
    setUseNativeRedeem(!useNativeRedeem)
  }, [useNativeRedeem])
  const handleResetClick = useCallback(() => {
    // dispatch(reset())
    dispatch(setStep(0))
  }, [dispatch])
  const howToAddTokensUrl = getHowToAddTokensToWalletUrl(targetChain)

  const relayerContent = (
    <>
      {!isReady && isEVMChain(targetChain) && !isTransferCompleted ? (
        <Typography className={classes.centered}>
          {'Please connect your wallet to check for transfer completion.'}
        </Typography>
      ) : null}

      {(!isEVMChain(targetChain) || isReady) && !isTransferCompleted ? (
        <div className={classes.centered}>
          <CircularProgress style={{ marginBottom: 16 }} />
          <Typography>
            {'Waiting for a relayer to process your transfer.'}
          </Typography>
          <Tooltip title="Your fees will be refunded on the target chain" arrow>
            <Button
              onClick={handleManuallyRedeemClick}
              size="small"
              variant="outlined"
              style={{ marginTop: 16 }}
            >
              Manually redeem instead
            </Button>
          </Tooltip>
        </div>
      ) : null}

      {isTransferCompleted ? (
        <RedeemPreview overrideExplainerString="Success! Your transfer is complete." />
      ) : null}
    </>
  )

  const nonRelayContent = (
    <>
      {isNativeEligible && (
        <FormControlLabel
          control={
            <Checkbox
              checked={useNativeRedeem}
              onChange={toggleNativeRedeem}
              color="primary"
            />
          }
          label="Automatically unwrap to native currency"
        />
      )}
      <>
        {' '}
        <ButtonWithLoader
          //TODO disable when the associated token account is confirmed to not exist
          disabled={
            !isReady ||
            disabled ||
            (isRecovery && (isTransferCompletedLoading || isTransferCompleted))
          }
          onClick={
            isNativeEligible && useNativeRedeem
              ? handleNativeClick
              : handleClick
          }
          showLoader={showLoader || (isRecovery && isTransferCompletedLoading)}
          error={statusMessage}
        >
          Redeem
        </ButtonWithLoader>
        <WaitingForWalletMessage />
      </>

      {useRelayer && !isTransferCompleted ? (
        <div className={classes.centered}>
          <Button
            onClick={handleSwitchToRelayViewClick}
            size="small"
            variant="outlined"
            style={{ marginTop: 16 }}
          >
            Return to relayer view
          </Button>
        </div>
      ) : null}

      {isRecovery && isReady && isTransferCompleted ? (
        <>
          <Alert severity="info" variant="outlined" className={classes.alert}>
            These tokens have already been redeemed.{' '}
            {!isEVMChain(targetChain) && howToAddTokensUrl ? (
              <Link
                href={howToAddTokensUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to see how to add them to your wallet.
              </Link>
            ) : null}
          </Alert>
          {targetAsset ? (
            <>
              <span>Token Address:</span>
              <SmartAddress
                chainId={targetChain}
                address={targetAsset || undefined}
                isAsset
              />
            </>
          ) : null}
          {isEVMChain(targetChain) ? <AddToMetamask /> : null}
          <ButtonWithLoader onClick={handleResetClick}>
            Back
          </ButtonWithLoader>
        </>
      ) : null}
    </>
  )

  return (
    <>
      <StepDescription>Receive the tokens on the target chain</StepDescription>
      {manualRedeem ? nonRelayContent : relayerContent}
    </>
  )
}

export default Redeem
