import { ChainId, isEVMChain } from '@certusone/wormhole-sdk'
import axios from 'axios'
import { formatUnits } from 'ethers/lib/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEthereumProvider } from '../contexts/EthereumProviderContext'
import {
  selectSourceWalletAddress,
  selectTransferSourceChain,
  selectTransferSourceParsedTokenAccounts,
} from '../store/selectors'
import {
  errorSourceParsedTokenAccounts,
  fetchSourceParsedTokenAccounts,
  ParsedTokenAccount,
  receiveSourceParsedTokenAccounts,
  setAmount,
  setSourceParsedTokenAccount,
  setSourceParsedTokenAccounts,
  setSourceWalletAddress,
} from '../store/transferSlice'
import { COVALENT_GET_TOKENS_URL, logoOverrides } from '../utils/consts'

export function createParsedTokenAccount(
  publicKey: string,
  mintKey: string,
  amount: string,
  decimals: number,
  uiAmount: number,
  uiAmountString: string,
  symbol?: string,
  name?: string,
  logo?: string,
  isNativeAsset?: boolean
): ParsedTokenAccount {
  return {
    publicKey: publicKey,
    mintKey: mintKey,
    amount,
    decimals,
    uiAmount,
    uiAmountString,
    symbol,
    name,
    logo,
    isNativeAsset,
  }
}

const createParsedTokenAccountFromCovalent = (
  walletAddress: string,
  covalent: CovalentData
): ParsedTokenAccount => {
  return {
    publicKey: walletAddress,
    mintKey: covalent.contract_address,
    amount: covalent.balance,
    decimals: covalent.contract_decimals,
    uiAmount: Number(formatUnits(covalent.balance, covalent.contract_decimals)),
    uiAmountString: formatUnits(covalent.balance, covalent.contract_decimals),
    symbol: covalent.contract_ticker_symbol,
    name: covalent.contract_name,
    logo: logoOverrides.get(covalent.contract_address) || covalent.logo_url,
  }
}

export type CovalentData = {
  contract_decimals: number
  contract_ticker_symbol: string
  contract_name: string
  contract_address: string
  logo_url: string | undefined
  balance: string
  quote: number | undefined
  quote_rate: number | undefined
}

const getEthereumAccountsCovalent = async (
  walletAddress: string,
  chainId: ChainId
): Promise<CovalentData[]> => {
  const url = COVALENT_GET_TOKENS_URL(chainId, walletAddress, false)

  try {
    const output = [] as CovalentData[]
    const response = await axios.get(url)
    const tokens = response.data.data.items

    if (tokens instanceof Array && tokens.length) {
      for (const item of tokens) {
        // TODO: filter?
        if (
          item.contract_decimals !== undefined &&
          item.contract_address &&
          item.balance &&
          item.balance !== '0' &&
          item.supports_erc?.includes('erc20')
        ) {
          output.push({ ...item } as CovalentData)
        }
      }
    }

    return output
  } catch (error) {
    return Promise.reject('Unable to retrieve your Ethereum Tokens.')
  }
}

/**
 * Fetches the balance of an asset for the connected wallet
 * This should handle every type of chain in the future, but only reads the Transfer state.
 */
function useGetAvailableTokens() {
  const dispatch = useDispatch()

  const tokenAccounts = useSelector(selectTransferSourceParsedTokenAccounts)
  const lookupChain = useSelector(selectTransferSourceChain)
  const { provider, signerAddress } = useEthereumProvider()

  const [covalent, setCovalent] = useState<any>(undefined)
  const [covalentLoading, setCovalentLoading] = useState(false)
  const [covalentError, setCovalentError] = useState<string | undefined>(
    undefined
  )

  const [ethNativeAccount, setEthNativeAccount] = useState<any>(undefined)
  const [ethNativeAccountLoading, setEthNativeAccountLoading] = useState(false)
  const [ethNativeAccountError, setEthNativeAccountError] = useState<
    string | undefined
  >(undefined)

  const selectedSourceWalletAddress = useSelector(selectSourceWalletAddress)
  const currentSourceWalletAddress: string | undefined = isEVMChain(lookupChain)
    ? signerAddress
    : undefined

  const resetSourceAccounts = useCallback(() => {
    dispatch(setSourceWalletAddress(undefined))
    dispatch(setSourceParsedTokenAccount(undefined))
    dispatch(setSourceParsedTokenAccounts(undefined))
    dispatch(setAmount(''))
    setCovalent(undefined) //These need to be included in the reset because they have balances on them.
    setCovalentLoading(false)
    setCovalentError('')

    setEthNativeAccount(undefined)
    setEthNativeAccountLoading(false)
    setEthNativeAccountError('')
  }, [setCovalent, dispatch])

  //TODO this useEffect could be somewhere else in the codebase
  //It resets the SourceParsedTokens accounts when the wallet changes
  useEffect(() => {
    if (
      selectedSourceWalletAddress !== undefined &&
      currentSourceWalletAddress !== undefined &&
      currentSourceWalletAddress !== selectedSourceWalletAddress
    ) {
      resetSourceAccounts()
      return
    }
  }, [
    selectedSourceWalletAddress,
    currentSourceWalletAddress,
    dispatch,
    resetSourceAccounts,
  ])

  useEffect(() => {
    resetSourceAccounts()
  }, [lookupChain, signerAddress])
  //Ethereum covalent accounts load
  useEffect(() => {
    let cancelled = false
    const walletAddress = signerAddress

    if (walletAddress && isEVMChain(lookupChain) && !covalent) {
      //TODO less cancel
      !cancelled && setCovalentLoading(true)
      !cancelled && dispatch(fetchSourceParsedTokenAccounts())
      getEthereumAccountsCovalent(walletAddress, lookupChain).then(
        (accounts) => {
          let _accounts: any = accounts
          if (
            accounts.findIndex((a) => a.contract_ticker_symbol === 'XCN') === -1
          ) {
            _accounts = [..._accounts, {
              balance: '0',
              balance_24h: null,
              contract_address:
                lookupChain === 4
                  ? '0x7324c7c0d95cebc73eea7e85cbaac0dbdf88a05b'
                  : '0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18',
              contract_decimals: 18,
              contract_name: 'XCN Token',
              contract_ticker_symbol: 'XCN',
              last_transferred_at: '',
              logo_url:
                'https://assets.coingecko.com/coins/images/24210/small/Chain_icon_200x200.png?1646895054',
              nft_data: null,
              quote: 0,
              quote_24h: null,
              quote_rate: 0,
              quote_rate_24h: null,
              supports_erc: ['erc20'],
              type: 'cryptocurrency',
            }]
          }
          !cancelled && setCovalentLoading(false)
          !cancelled && setCovalentError(undefined)
          !cancelled && setCovalent(_accounts)
          !cancelled &&
            dispatch(
              receiveSourceParsedTokenAccounts(
                _accounts.map((x: any) =>
                  createParsedTokenAccountFromCovalent(walletAddress, x)
                )
              )
            )
        },
        () => {
          !cancelled &&
            dispatch(
              errorSourceParsedTokenAccounts(
                'Cannot load your Ethereum tokens at the moment.'
              )
            )
          !cancelled &&
            setCovalentError('Cannot load your Ethereum tokens at the moment.')
          !cancelled && setCovalentLoading(false)
        }
      )

      return () => {
        cancelled = true
      }
    }
  }, [lookupChain, provider, signerAddress, dispatch, covalent])

  const ethAccounts = useMemo(() => {
    const output = { ...tokenAccounts }
    output.data = output.data?.slice() || []
    output.isFetching = output.isFetching || ethNativeAccountLoading
    output.error = output.error || ethNativeAccountError
    ethNativeAccount && output.data && output.data.unshift(ethNativeAccount)
    return output
  }, [
    ethNativeAccount,
    ethNativeAccountLoading,
    ethNativeAccountError,
    tokenAccounts,
  ])
  return isEVMChain(lookupChain)
    ? {
        tokenAccounts: ethAccounts,
        covalent: {
          data: covalent,
          isFetching: covalentLoading,
          error: covalentError,
          receivedAt: null, //TODO
        },
        resetAccounts: resetSourceAccounts,
      }
    : undefined
}

export default useGetAvailableTokens
