import {
  ChainId,
  getForeignAssetEth,
  hexToNativeAssetString,
  hexToUint8Array,
  isEVMChain,
} from "@certusone/wormhole-sdk";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEthereumProvider } from "../contexts/EthereumProviderContext";
import {
  errorDataWrapper,
  fetchDataWrapper,
  receiveDataWrapper,
} from "../store/helpers";
import {
  selectTransferIsSourceAssetWormholeWrapped,
  selectTransferOriginAsset,
  selectTransferOriginChain,
  selectTransferTargetChain,
} from "../store/selectors";
import { setTargetAsset as setTransferTargetAsset } from "../store/transferSlice";
import {
  getEvmChainId,
  getTokenBridgeAddressForChain,
} from "../utils/consts";

function useFetchTargetAsset(nft?: boolean) {
  const dispatch = useDispatch();
  const isSourceAssetWormholeWrapped = useSelector(
    selectTransferIsSourceAssetWormholeWrapped
  );
  const originChain = useSelector(
    selectTransferOriginChain
  );
  const originAsset = useSelector(
    selectTransferOriginAsset
  );
  const tokenId = ""; // this should exist by this step for NFT transfers
  const targetChain = useSelector(
    selectTransferTargetChain
  );
  const setTargetAsset = setTransferTargetAsset
  const { provider, chainId: evmChainId } = useEthereumProvider();
  const correctEvmNetwork = getEvmChainId(targetChain);
  const hasCorrectEvmNetwork = evmChainId === correctEvmNetwork;
  const [lastSuccessfulArgs, setLastSuccessfulArgs] = useState<{
    isSourceAssetWormholeWrapped: boolean | undefined;
    originChain: ChainId | undefined;
    originAsset: string | undefined;
    targetChain: ChainId;
    nft?: boolean;
    tokenId?: string;
  } | null>(null);
  const argsMatchLastSuccess =
    !!lastSuccessfulArgs &&
    lastSuccessfulArgs.isSourceAssetWormholeWrapped ===
      isSourceAssetWormholeWrapped &&
    lastSuccessfulArgs.originChain === originChain &&
    lastSuccessfulArgs.originAsset === originAsset &&
    lastSuccessfulArgs.targetChain === targetChain &&
    lastSuccessfulArgs.nft === nft &&
    lastSuccessfulArgs.tokenId === tokenId;
  const setArgs = useCallback(
    () =>
      setLastSuccessfulArgs({
        isSourceAssetWormholeWrapped,
        originChain,
        originAsset,
        targetChain,
        nft,
        tokenId,
      }),
    [
      isSourceAssetWormholeWrapped,
      originChain,
      originAsset,
      targetChain,
      nft,
      tokenId,
    ]
  );

  useEffect(() => {
    if (argsMatchLastSuccess) {
      return;
    }
    setLastSuccessfulArgs(null);
    if (evmChainId === 1) {
      dispatch(
        setTargetAsset(
          receiveDataWrapper({
            doesExist: true,
            address: '0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18',
          })
        )
      );
      setArgs();
      return;
    }
    let cancelled = false;
    (async () => {
      if (
        evmChainId === 56
      ) {
        dispatch(setTargetAsset(fetchDataWrapper()));
        try {
          if (!cancelled) {
            dispatch(
              setTargetAsset(
                receiveDataWrapper({
                  doesExist: true,
                  address: '0x7324c7c0d95cebc73eea7e85cbaac0dbdf88a05b',
                })
              )
            );
            setArgs();
          }
        } catch (e) {
          if (!cancelled) {
            dispatch(
              setTargetAsset(
                errorDataWrapper(
                  "Unable to determine existence of wrapped asset"
                )
              )
            );
          }
        }
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [
    dispatch,
    evmChainId,
    isSourceAssetWormholeWrapped,
    originChain,
    originAsset,
    targetChain,
    provider,
    setTargetAsset,
    tokenId,
    hasCorrectEvmNetwork,
    argsMatchLastSuccess,
    setArgs,
  ]);

  // useEffect(() => {
  //   if (argsMatchLastSuccess) {
  //     return;
  //   }
  //   setLastSuccessfulArgs(null);
  //   if (isSourceAssetWormholeWrapped && originChain === targetChain) {
  //     dispatch(
  //       setTargetAsset(
  //         receiveDataWrapper({
  //           doesExist: true,
  //           address: hexToNativeAssetString(originAsset, originChain) || null,
  //         })
  //       )
  //     );
  //     setArgs();
  //     return;
  //   }
  //   let cancelled = false;
  //   (async () => {
  //     if (
  //       isEVMChain(targetChain) &&
  //       provider &&
  //       hasCorrectEvmNetwork &&
  //       originChain &&
  //       originAsset
  //     ) {
  //       dispatch(setTargetAsset(fetchDataWrapper()));
  //       try {
  //         const asset = await (getForeignAssetEth(
  //               getTokenBridgeAddressForChain(targetChain),
  //               provider,
  //               originChain,
  //               hexToUint8Array(originAsset)
  //             ));
  //         if (!cancelled) {
  //           dispatch(
  //             setTargetAsset(
  //               receiveDataWrapper({
  //                 doesExist: asset !== ethers.constants.AddressZero,
  //                 address: asset,
  //               })
  //             )
  //           );
  //           setArgs();
  //         }
  //       } catch (e) {
  //         if (!cancelled) {
  //           dispatch(
  //             setTargetAsset(
  //               errorDataWrapper(
  //                 "Unable to determine existence of wrapped asset"
  //               )
  //             )
  //           );
  //         }
  //       }
  //     }
  //   })();
  //   return () => {
  //     cancelled = true;
  //   };
  // }, [
  //   dispatch,
  //   isSourceAssetWormholeWrapped,
  //   originChain,
  //   originAsset,
  //   targetChain,
  //   provider,
  //   setTargetAsset,
  //   tokenId,
  //   hasCorrectEvmNetwork,
  //   argsMatchLastSuccess,
  //   setArgs,
  // ]);
}

export default useFetchTargetAsset;
