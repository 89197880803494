import {
  ChainId,
  isEVMChain,
} from "@certusone/wormhole-sdk";
import { useMemo } from "react";
import { DataWrapper, getEmptyDataWrapper } from "../store/helpers";
import { logoOverrides } from "../utils/consts";
import useEvmMetadata, { EvmMetadata } from "./useEvmMetadata";

export type GenericMetadata = {
  symbol?: string;
  logo?: string;
  tokenName?: string;
  decimals?: number;
  //TODO more items
  raw?: any;
};

const constructEthMetadata = (
  addresses: string[],
  metadataMap: DataWrapper<Map<string, EvmMetadata> | null>
) => {
  const isFetching = metadataMap.isFetching;
  const error = metadataMap.error;
  const receivedAt = metadataMap.receivedAt;
  const data = new Map<string, GenericMetadata>();
  addresses.forEach((address) => {
    const meta = metadataMap.data?.get(address);
    const obj = {
      symbol: meta?.symbol || undefined,
      logo: logoOverrides.get(address) || meta?.logo || undefined,
      tokenName: meta?.tokenName || undefined,
      decimals: meta?.decimals,
    };
    data.set(address, obj);
  });

  return {
    isFetching,
    error,
    receivedAt,
    data,
  };
};

export default function useMetadata(
  chainId: ChainId,
  addresses: string[]
): DataWrapper<Map<string, GenericMetadata>> {

  const ethereumAddresses = useMemo(() => {
    return isEVMChain(chainId) ? addresses : [];
  }, [chainId, addresses]);

  const ethMetadata = useEvmMetadata(ethereumAddresses, chainId);

  const output: DataWrapper<Map<string, GenericMetadata>> = useMemo(
    () =>
        isEVMChain(chainId)
        ? constructEthMetadata(ethereumAddresses, ethMetadata)
        : getEmptyDataWrapper(),
    [
      chainId,
      ethereumAddresses,
      ethMetadata,
    ]
  );

  return output;
}
