import { makeStyles } from '@material-ui/core'
import { useCallback, useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import useIsWalletReady from '../../hooks/useIsWalletReady'
import useGetSourceParsedTokens from '../../hooks/useGetSourceParsedTokenAccounts'
import {
  selectTransferAmount,
  selectTransferIsSourceComplete,
  selectTransferShouldLockFields,
  selectTransferSourceBalanceString,
  selectTransferSourceChain,
  selectTransferSourceError,
  selectTransferSourceParsedTokenAccount,
  selectTransferTargetChain,
} from '../../store/selectors'
import {
  incrementStep,
  setAmount,
  setSourceChain,
  setTargetChain,
  setSourceParsedTokenAccount as setTransferSourceParsedTokenAccount,
  setSourceWalletAddress as setTransferSourceWalletAddress,
} from '../../store/transferSlice'
import useGetTargetParsedTokenAccounts from '../../hooks/useGetTargetParsedTokenAccounts'
import useSyncTargetAddress from '../../hooks/useSyncTargetAddress'
import { CHAINS, getIsTransferDisabled } from '../../utils/consts'
import ButtonWithLoader from '../ButtonWithLoader'
import ChainSelect from '../ChainSelect'
import ChainSelectArrow from '../ChainSelectArrow'
import LowBalanceWarning from '../LowBalanceWarning'
import ChainWarningMessage from '../ChainWarningMessage'
import KeyAndBalance from '../KeyAndBalance'

const useStyles = makeStyles((theme) => ({
  chainSelectWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  chainSelectContainer: {
    width: '100%',
    flexBasis: '100%',
  },
  chainSelectArrow: {
    position: 'relative',
    top: '0px',
    transform: 'rotate(90deg)',
  },
  inputBoxWrap: {
    width: '100%',
    borderRadius: 20,
    border: '1px solid rgb(25, 27, 31)',
    backgroundColor: 'rgb(33, 36, 41)',
    '&:hover': {
      border: '1px solid rgb(64, 68, 79)',
    },
  },
  currencyInputPanel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    borderRadius: 20,
    '& input': {
      filter: 'none',
      opacity: 1,
      transition: 'opacity 0.2s ease-in-out 0s',
      textAlign: 'left',
      color: 'rgb(255, 255, 255)',
      width: 0,
      position: 'relative',
      fontWeight: 500,
      outline: 'none',
      border: 'none',
      flex: '1 1 auto',
      backgroundColor: 'rgb(33, 36, 41)',
      fontSize: 28,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: 0,
      appearance: 'textfield',
    },
    '& button': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgb(44, 47, 54)',
      boxShadow: 'rgb(0 0 0 / 8%) 0px 6px 10px',
      color: 'rgb(255, 255, 255)',
      borderRadius: 16,
      outline: 'none',
      userSelect: 'none',
      border: 'none',
      fontSize: 24,
      fontWeight: 500,
      height: '2.4rem',
      width: 'initial',
      padding: '0px 8px',
      justifyContent: 'space-between',
      marginLeft: 12,
      visibility: 'visible',
      '& img': {
        width: 24,
        height: 24,
        borderRadius: '50%',
        boxShadow: 'black 0px 0px 1px',
        border: '0px solid rgba(255, 255, 255, 0)',
        marginRight: '0.5rem',
      },
      '& span': {
        margin: '0px 0.25rem',
        fontSize: 18,
      },
    },
  },
  currencyBalancePanel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: 'rgb(255, 255, 255)',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    padding: '0px 1rem 1rem',
    '& .balanceWrap': {
      display: 'flex',
      alignItems: 'center',
      '& .balance': {
        boxSizing: 'border-box',
        margin: 0,
        minWidth: 0,
        fontWeight: 500,
        fontSize: 14,
        color: 'rgb(143, 150, 172)',
      },
      '& button': {
        backgroundColor: 'rgba(21, 61, 111, 0.44)',
        border: 'none',
        borderRadius: 12,
        color: 'rgb(0, 191, 170)',
        cursor: 'pointer',
        fontSize: 11,
        fontWeight: 500,
        marginLeft: '0.25rem',
        opacity: 1,
        padding: '4px 6px',
        pointerEvents: 'initial',
      },
    },
  },
  transferField: {
    marginTop: theme.spacing(1),
  },
}))

function Source() {
  useGetTargetParsedTokenAccounts()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { account, chainId } = useActiveWeb3React()
  const sourceChain = useSelector(selectTransferSourceChain)
  const targetChain = useSelector(selectTransferTargetChain)
  const maps = useGetSourceParsedTokens()
  const setSourceParsedTokenAccount = setTransferSourceParsedTokenAccount
  const setSourceWalletAddress = setTransferSourceWalletAddress

  const targetChainOptions = useMemo(
    () => CHAINS.filter((c) => c.id !== sourceChain),
    [sourceChain]
  )
  const isSourceTransferDisabled = useMemo(() => {
    return getIsTransferDisabled(sourceChain, true)
  }, [sourceChain])
  const isTargetTransferDisabled = useMemo(() => {
    return getIsTransferDisabled(targetChain, false)
  }, [targetChain])
  const parsedTokenAccount = useSelector(selectTransferSourceParsedTokenAccount)
  const hasParsedTokenAccount = !!parsedTokenAccount
  const uiAmountString = useSelector(selectTransferSourceBalanceString)
  const amount = useSelector(selectTransferAmount)
  const error = useSelector(selectTransferSourceError)
  const isSourceComplete = useSelector(selectTransferIsSourceComplete)
  const shouldLockFields = useSelector(selectTransferShouldLockFields)
  const [isAutoSwitch, setIsAutoSwitch] = useState(false)
  const walletIsReady = useIsWalletReady(sourceChain, isAutoSwitch)

  useSyncTargetAddress(!shouldLockFields)
  useEffect(() => {
    if (
      maps &&
      maps.tokenAccounts &&
      maps.tokenAccounts.data &&
      maps.tokenAccounts.data.length !== 0
    ) {
      const xcnTokenAccount = maps.tokenAccounts.data.find(
        (t) => t.symbol === 'XCN'
      )
      if (!xcnTokenAccount) {
        dispatch(setSourceParsedTokenAccount(undefined))
        dispatch(setSourceWalletAddress(undefined))
      } else if (xcnTokenAccount !== undefined && walletIsReady.walletAddress) {
        dispatch(setSourceParsedTokenAccount(xcnTokenAccount))
        dispatch(setSourceWalletAddress(walletIsReady.walletAddress))
      }
    }
  }, [
    dispatch,
    maps,
    setSourceParsedTokenAccount,
    setSourceWalletAddress,
    walletIsReady,
  ])

  useEffect(() => {
    if (chainId && !isAutoSwitch) {
      if (chainId === 56) {
        dispatch(setSourceChain(4))
      } else {
        dispatch(setSourceChain(2))
      }
    }
  }, [chainId, isAutoSwitch])

  const handleSourceChange = useCallback(
    (event: any) => {
      dispatch(setSourceChain(event.target.value))
      setIsAutoSwitch(true)
    },
    [dispatch]
  )
  const handleTargetChange = useCallback(
    (event: any) => {
      dispatch(setTargetChain(event.target.value))
      setIsAutoSwitch(true)
    },
    [dispatch]
  )
  const handleAmountChange = useCallback(
    (event: any) => {
      dispatch(setAmount(event.target.value))
    },
    [dispatch]
  )
  const handleMaxClick = useCallback(() => {
    if (uiAmountString) {
      dispatch(setAmount(uiAmountString))
    }
  }, [dispatch, uiAmountString])
  const handleNextClick = useCallback(() => {
    dispatch(incrementStep())
  }, [dispatch])
  return (
    <>
      <div
        className={classes.chainSelectWrapper}
        style={{ marginBottom: '25px' }}
      >
        <div className={classes.chainSelectContainer}>
          <ChainSelect
            select
            variant="outlined"
            fullWidth
            value={sourceChain}
            onChange={handleSourceChange}
            disabled={shouldLockFields}
            chains={CHAINS}
          />
        </div>
        <div className={classes.chainSelectArrow}>
          <ChainSelectArrow
            onClick={() => {
              dispatch(setSourceChain(targetChain))
              setIsAutoSwitch(true)
            }}
            disabled={shouldLockFields}
          />
        </div>
        <div className={classes.chainSelectContainer}>
          <ChainSelect
            variant="outlined"
            select
            fullWidth
            value={targetChain}
            onChange={handleTargetChange}
            disabled={shouldLockFields}
            chains={targetChainOptions}
          />
        </div>
      </div>
      <LowBalanceWarning chainId={sourceChain} />
      {account && (
        <div className={classes.inputBoxWrap}>
          <div className={classes.currencyInputPanel}>
            <input
              placeholder="0.0"
              value={amount}
              disabled={shouldLockFields}
              onChange={handleAmountChange}
            />
            <button>
              <img
                src="https://etherscan.io/token/images/onyxcoin_32.png"
                alt="xcn"
              />
              <span>XCN</span>
            </button>
          </div>
          <div className={classes.currencyBalancePanel}>
            <div className="balanceWrap">
              <div className="balance">Balance: {uiAmountString || '~'}</div>
              <button
                onClick={
                  uiAmountString && !parsedTokenAccount?.isNativeAsset
                    ? handleMaxClick
                    : undefined
                }
              >
                MAX
              </button>
            </div>
          </div>
        </div>
      )}
      <ChainWarningMessage chainId={sourceChain} />
      <ChainWarningMessage chainId={targetChain} />
      {!account ? (
        <KeyAndBalance chainId={sourceChain} />
      ) : (
        <ButtonWithLoader
          disabled={
            !isSourceComplete ||
            isSourceTransferDisabled ||
            isTargetTransferDisabled
          }
          onClick={handleNextClick}
          showLoader={false}
          error={error}
        >
          Next
        </ButtonWithLoader>
      )}
    </>
  )
}

export default Source
