import { createTheme, responsiveFontSizes } from "@material-ui/core";
import bg from "../src/images/bg.svg";
import InterRoman from "./fonts/Inter-roman.woff2";

export const COLORS = {
  blue: "#1975e6",
  blueWithTransparency: "rgba(25, 117, 230, 0.8)",
  gray: "#4e4e54",
  green: "#0ac2af",
  greenWithTransparency: "rgba(10, 194, 175, 0.8)",
  lightGreen: "rgba(51, 242, 223, 1)",
  lightBlue: "#83b9fc",
  nearBlack: "rgb(33, 36, 41)",
  nearBlackWithMinorTransparency: "rgba(0,0,0,.25)",
  red: "#aa0818",
  darkRed: "#810612",
  white: "#FFFFFF",
  whiteWithTransparency: "rgb(25, 27, 31)",
};

const interRoman = {
  fontFamily: "Inter",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `url(${InterRoman}) format('woff2')`,
};

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      type: "dark",
      background: {
        default: COLORS.nearBlack,
        paper: COLORS.nearBlack,
      },
      divider: COLORS.white,
      text: {
        primary: COLORS.white,
      },
      primary: {
        main: COLORS.blueWithTransparency, // #0074FF
        light: COLORS.lightBlue,
      },
      secondary: {
        main: COLORS.greenWithTransparency, // #00EFD8
        light: COLORS.lightGreen,
      },
      error: {
        main: COLORS.red,
      },
    },

    typography: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 13,
      lineHeight: 1,
      h1: {
        fontFamily: "Inter, sans-serif",
        fontWeight: "bold",
      },
      h2: {
        fontWeight: "200",
      },
      h4: {
        fontWeight: "600",
        fontFamily: "Inter, sans-serif",
      },
    },
    overrides: {
      MuiContainer: {
        root: {
          zIndex: 2
        }
      },
      MuiCssBaseline: {
        "@global": {
          "@font-face": [interRoman],
          body: {
            overscrollBehaviorY: "none",
            lineHeight: 1,
          },
          "*": {
            fontFamily: "'Inter', sans-serif",
            scrollbarWidth: "thin",
            scrollbarColor: `${COLORS.gray} ${COLORS.nearBlackWithMinorTransparency}`,
          },
          "*::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
            backgroundColor: COLORS.nearBlackWithMinorTransparency,
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: COLORS.gray,
            borderRadius: "4px",
          },
          "*::-webkit-scrollbar-corner": {
            // this hides an annoying white box which appears when both scrollbars are present
            backgroundColor: "transparent",
          },
        },
      },
      MuiAccordion: {
        root: {
          backgroundColor: COLORS.whiteWithTransparency,
          "&:before": {
            display: "none",
          },
        },
        rounded: {
          "&:first-child": {
            borderTopLeftRadius: "28px",
            borderTopRightRadius: "28px",
          },
          "&:last-child": {
            borderBottomLeftRadius: "28px",
            borderBottomRightRadius: "28px",
          },
        },
      },
      MuiAlert: {
        root: {
          borderRadius: 16,
          
        },
        outlinedInfo: {
          border: "1px solid rgb(44, 47, 54)",
          background: 'rgb(33, 36, 41)',
          color: "#fff",
        },
        icon: {
          color: "#fff",
        },
      },
      MuiButton: {
        root: {
          borderRadius: "20px",
        },
        outlinedSizeSmall: {
          padding: "6px 9px",
          fontSize: "0.70rem",
        },
      },
      MuiLink: {
        root: {
          color: COLORS.lightBlue,
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: "28px",
          backdropFilter: "blur(4px)",
        },
      },
      MuiStepper: {
        root: {
          backgroundColor: "transparent",
          padding: 0,
        },
      },
      MuiStep: {
        root: {
          backgroundColor: COLORS.whiteWithTransparency,
          boxShadow: "rgb(0 0 0 / 1%) 0px 0px 1px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 16px 24px, rgb(0 0 0 / 1%) 0px 24px 32px",
          borderRadius: "24px",
          padding: "24px 16px 8px",
        },
      },
      MuiStepConnector: {
        lineVertical: {
          borderLeftWidth: 0,
        },
      },
      MuiStepContent: {
        root: {
          borderLeftWidth: 0,
          marginLeft: 0,
          paddingLeft: 0,
          paddingRight: 0,
          marginTop: 16
        },
      },
      MuiStepLabel: {
        label: {
          color: COLORS.white,
          fontSize: 15,
          // textTransform: "uppercase",
          "&.MuiStepLabel-active": {
            fontWeight: 600
          },
          "&.MuiStepLabel-completed": {},
        },
      },
      MuiTabs: {
        root: {
          width: '100%',
          backgroundColor: 'rgb(25, 27, 31)',
          padding: 2,
          borderRadius: 16,
          border: 'none',
          display: 'grid',
          gridAutoFlow: 'column',
          gap: 10,
          overflow: 'hidden',
          alignItems: 'center'
        },
        indicator: {
          height: "100%",
          background: "linear-gradient(20deg, #f44b1b 0%, #eeb430 100%)",
          zIndex: -1,
        },
        scroller: {
          borderRadius: 16,
        }
      },
      MuiTab: {
        root: {
          color: 'rgb(195, 197, 203)',
          fontFamily: "Inter Roman, sans-serif",
          fontWeight: 500,
          fontSize: '1rem',
          letterSpacing: "-0.69px",
          textTransform: "none",
        },
        textColorInherit: {
          opacity: 1,
          color: 'rgb(195, 197, 203)',
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: "none",
        },
      },
      MuiTooltip: {
        root: {
          minWidth: 'max-content',
          textAlign: 'center',
          padding: 12,
          border: '1px solid rgb(44, 47, 54)',
          background: 'rgb(25, 27, 31)',
          borderRadius: 16,
        },
        tooltipArrow: {
          borderColor: 'rgb(25, 27, 31)',
        }
      }
    },
  })
);
