import { isEVMChain } from '@certusone/wormhole-sdk'
import { Button, makeStyles } from '@material-ui/core'
import detectEthereumProvider from '@metamask/detect-provider'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useEthereumProvider } from '../../contexts/EthereumProviderContext'
import {
  selectTransferSourceParsedTokenAccount,
  selectTransferTargetAsset,
  selectTransferTargetChain,
} from '../../store/selectors'
import { getEvmChainId } from '../../utils/consts'

const useStyles = makeStyles((theme) => ({
  addButton: {
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0px`,
  },
}))

export default function AddToMetamask() {
  const classes = useStyles()
  const sourceParsedTokenAccount = useSelector(
    selectTransferSourceParsedTokenAccount
  )
  const targetChain = useSelector(selectTransferTargetChain)
  const targetAsset = useSelector(selectTransferTargetAsset)
  const { provider, signerAddress, chainId: evmChainId } = useEthereumProvider()
  const hasCorrectEvmNetwork = evmChainId === getEvmChainId(targetChain)
  const handleClick = useCallback(() => {
    if (provider && targetAsset && signerAddress && hasCorrectEvmNetwork) {
      ;(async () => {
        try {
          const ethereum = (await detectEthereumProvider()) as any
          ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20', // In the future, other standards will be supported
              options: {
                address:  evmChainId === 1
                ? '0xa2cd3d43c775978a96bdbf12d733d5a1ed94fb18'
                : '0x7324c7c0d95cebc73eea7e85cbaac0dbdf88a05b',
                symbol: 'XCN',
                decimals: 18
                // image: string; // A string url of the token logo
              },
            },
          })
        } catch (e) {
          console.error(e)
        }
      })()
    }
  }, [
    provider,
    targetAsset,
    evmChainId,
    signerAddress,
    hasCorrectEvmNetwork,
    sourceParsedTokenAccount,
  ])
  return provider &&
    signerAddress &&
    targetAsset &&
    isEVMChain(targetChain) &&
    hasCorrectEvmNetwork ? (
    <Button
      onClick={handleClick}
      size="small"
      variant="outlined"
      className={classes.addButton}
    >
      Add to Metamask
    </Button>
  ) : null
}
