import { Typography } from '@material-ui/core'
import { useEthereumProvider } from '../contexts/EthereumProviderContext'
import { useWalletModalToggle } from '../state/application/hooks'
import ToggleConnectedButton from './ToggleConnectedButton'

const EthereumSignerKey = () => {
  const { connect, disconnect, signerAddress, providerError } =
    useEthereumProvider()
  const toggleWalletModal = useWalletModalToggle()
  return (
    <>
      <ToggleConnectedButton
        connect={toggleWalletModal}
        disconnect={disconnect as () => void}
        pk={signerAddress || ''}
      />
      {/* {providerError ? (
        <Typography variant="body2" color="error">
          {providerError}
        </Typography>
      ) : null} */}
    </>
  )
}

export default EthereumSignerKey
