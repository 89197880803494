import {
  ChainId,
  getEmitterAddressEth,
  hexToNativeAssetString,
  hexToNativeString,
  hexToUint8Array,
  importCoreWasm,
  isEVMChain,
  parseSequenceFromLogEth,
  parseTransferPayload,
  uint8ArrayToHex,
} from '@certusone/wormhole-sdk'
import {
  Card,
  Container,
  Divider,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import axios from 'axios'
import { ethers } from 'ethers'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useEthereumProvider } from '../contexts/EthereumProviderContext'
import useIsWalletReady from '../hooks/useIsWalletReady'
import useRelayersAvailable, { Relayer } from '../hooks/useRelayersAvailable'
import { COLORS } from '../muiTheme'
import { setRecoveryVaa } from '../store/transferSlice'
import {
  CHAINS,
  CHAINS_BY_ID,
  getBridgeAddressForChain,
  getTokenBridgeAddressForChain,
  RELAY_URL_EXTENSION,
  WORMHOLE_RPC_HOSTS,
} from '../utils/consts'
import { getSignedVAAWithRetry } from '../utils/getSignedVAAWithRetry'
import parseError from '../utils/parseError'
import ButtonWithLoader from './ButtonWithLoader'
import ChainSelect from './ChainSelect'
import RelaySelector from './RelaySelector'

const useStyles = makeStyles((theme) => ({
  mainCard: {
    padding: '24px 16px 8px',
    backgroundColor: COLORS.whiteWithTransparency,
  },
  advancedContainer: {
    padding: theme.spacing(2, 0),
  },
  relayAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& > .MuiAlert-message': {
      width: '100%',
    },
  },
  txInput: {
    borderRadius: 20,
    backgroundColor: 'rgb(33, 36, 41)',
    outline: 'none',
    width: '100%',
    padding: 16,
    fontSize: 16,
    color: '#fff',
    border: '1px solid transparent',
    marginTop: 16,
    '&:hover': {
      border: '1px solid rgb(64, 68, 79)',
    }
  },
  errorMessage: {
    color: '#ff4052'
  }
}))

async function evm(
  provider: ethers.providers.Web3Provider,
  tx: string,
  enqueueSnackbar: any,
  chainId: ChainId
) {
  try {
    const receipt = await provider.getTransactionReceipt(tx)
    const sequence = parseSequenceFromLogEth(
      receipt,
      getBridgeAddressForChain(chainId)
    )
    const emitterAddress = getEmitterAddressEth(
      getTokenBridgeAddressForChain(chainId)
    )
    const { vaaBytes } = await getSignedVAAWithRetry(
      chainId,
      emitterAddress,
      sequence.toString(),
      WORMHOLE_RPC_HOSTS.length
    )
    return { vaa: uint8ArrayToHex(vaaBytes), error: null }
  } catch (e) {
    console.error(e)
    enqueueSnackbar(null, {
      content: <Alert severity="error">{parseError(e)}</Alert>,
    })
    return { vaa: null, error: parseError(e) }
  }
}

function RelayerRecovery({
  parsedPayload,
  signedVaa,
  onClick,
}: {
  parsedPayload: any
  signedVaa: string
  onClick: () => void
}) {
  const classes = useStyles()
  const relayerInfo = useRelayersAvailable(true)
  const [selectedRelayer, setSelectedRelayer] = useState<Relayer | null>(null)
  const [isAttemptingToSchedule, setIsAttemptingToSchedule] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  console.log(parsedPayload, relayerInfo, 'in recovery relayer')

  const fee =
    (parsedPayload && parsedPayload.fee && parseInt(parsedPayload.fee)) || null
  //This check is probably more sophisticated in the future. Possibly a net call.
  const isEligible =
    fee &&
    fee > 0 &&
    relayerInfo?.data?.relayers?.length &&
    relayerInfo?.data?.relayers?.length > 0

  const handleRelayerChange = useCallback(
    (relayer: Relayer | null) => {
      setSelectedRelayer(relayer)
    },
    [setSelectedRelayer]
  )

  const handleGo = useCallback(async () => {
    console.log('handle go', selectedRelayer, parsedPayload)
    if (!(selectedRelayer && selectedRelayer.url)) {
      return
    }

    setIsAttemptingToSchedule(true)
    axios
      .get(
        selectedRelayer.url +
          RELAY_URL_EXTENSION +
          encodeURIComponent(
            Buffer.from(hexToUint8Array(signedVaa)).toString('base64')
          )
      )
      .then(
        () => {
          setIsAttemptingToSchedule(false)
          onClick()
        },
        (error) => {
          setIsAttemptingToSchedule(false)
          enqueueSnackbar(null, {
            content: (
              <Alert severity="error">
                {'Relay request rejected. Error: ' + error.message}
              </Alert>
            ),
          })
        }
      )
  }, [selectedRelayer, enqueueSnackbar, onClick, signedVaa, parsedPayload])

  if (!isEligible) {
    return null
  }

  return (
    <Alert variant="outlined" severity="info" className={classes.relayAlert}>
      <Typography>{'This transaction is eligible to be relayed'}</Typography>
      <RelaySelector
        selectedValue={selectedRelayer}
        onChange={handleRelayerChange}
      />
      <ButtonWithLoader
        disabled={!selectedRelayer}
        onClick={handleGo}
        showLoader={isAttemptingToSchedule}
      >
        Request Relay
      </ButtonWithLoader>
    </Alert>
  )
}

export default function Recovery() {
  const classes = useStyles()
  const { push } = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const { provider } = useEthereumProvider()
  const [type, setType] = useState('Token')
  const [recoverySourceChain, setRecoverySourceChain] =
    useState<ChainId>(1)
  const [recoverySourceTx, setRecoverySourceTx] = useState('')
  const [recoverySourceTxIsLoading, setRecoverySourceTxIsLoading] =
    useState(false)
  const [recoverySourceTxError, setRecoverySourceTxError] = useState('')
  const [recoverySignedVAA, setRecoverySignedVAA] = useState('')
  const [recoveryParsedVAA, setRecoveryParsedVAA] = useState<any>(null)
  const { isReady, statusMessage } = useIsWalletReady(recoverySourceChain)
  const walletConnectError =
    isEVMChain(recoverySourceChain) && !isReady ? statusMessage : ''
  const parsedPayload = useMemo(() => {
    try {
      return recoveryParsedVAA?.payload
        ? parseTransferPayload(
            Buffer.from(new Uint8Array(recoveryParsedVAA.payload))
          )
        : null
    } catch (e) {
      console.error(e)
      return null
    }
  }, [recoveryParsedVAA])

  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const pathSourceChain = query.get('sourceChain')
  const pathSourceTransaction = query.get('transactionId')

  //This effect initializes the state based on the path params.
  useEffect(() => {
    if (!pathSourceChain && !pathSourceTransaction) {
      return
    }
    try {
      const sourceChain: ChainId =
        CHAINS_BY_ID[parseFloat(pathSourceChain || '') as ChainId]?.id

      if (sourceChain) {
        setRecoverySourceChain(sourceChain)
      }
      if (pathSourceTransaction) {
        setRecoverySourceTx(pathSourceTransaction)
      }
    } catch (e) {
      console.error(e)
      console.error('Invalid path params specified.')
    }
  }, [pathSourceChain, pathSourceTransaction])

  useEffect(() => {
    if (recoverySourceTx && (!isEVMChain(recoverySourceChain) || isReady)) {
      let cancelled = false
      if (isEVMChain(recoverySourceChain) && provider) {
        setRecoverySourceTxError('')
        setRecoverySourceTxIsLoading(true)
        ;(async () => {
          const { vaa, error } = await evm(
            provider,
            recoverySourceTx,
            enqueueSnackbar,
            recoverySourceChain
          )
          if (!cancelled) {
            setRecoverySourceTxIsLoading(false)
            if (vaa) {
              setRecoverySignedVAA(vaa)
            }
            if (error) {
              setRecoverySourceTxError(error)
            }
          }
        })()
      }
      return () => {
        cancelled = true
      }
    }
  }, [
    recoverySourceChain,
    recoverySourceTx,
    provider,
    enqueueSnackbar,
    isReady,
  ])

  const handleSourceChainChange = useCallback((event: any) => {
    setRecoverySourceTx('')
    setRecoverySourceChain(event.target.value)
  }, [])
  const handleSourceTxChange = useCallback((event: any) => {
    setRecoverySourceTx(event.target.value.trim())
  }, [])
  const handleSignedVAAChange = useCallback((event: any) => {
    setRecoverySignedVAA(event.target.value.trim())
  }, [])
  useEffect(() => {
    let cancelled = false
    if (recoverySignedVAA) {
      ;(async () => {
        try {
          const { parse_vaa } = await importCoreWasm()
          const parsedVAA = parse_vaa(hexToUint8Array(recoverySignedVAA))
          if (!cancelled) {
            setRecoveryParsedVAA(parsedVAA)
          }
        } catch (e) {
          console.log(e)
          if (!cancelled) {
            setRecoveryParsedVAA(null)
          }
        }
      })()
    }
    return () => {
      cancelled = true
    }
  }, [recoverySignedVAA])
  const parsedPayloadTargetChain = parsedPayload?.targetChain
  const enableRecovery = recoverySignedVAA && parsedPayloadTargetChain

  const handleRecoverClickBase = useCallback(
    (useRelayer: boolean) => {
      if (enableRecovery && recoverySignedVAA && parsedPayloadTargetChain) {
        // TODO: make recovery reducer
        dispatch(
          setRecoveryVaa({
            vaa: recoverySignedVAA,
            useRelayer,
            parsedPayload: {
              targetChain: parsedPayload.targetChain,
              targetAddress: parsedPayload.targetAddress,
              originChain: parsedPayload.originChain,
              originAddress: parsedPayload.originAddress,
              amount:
                'amount' in parsedPayload
                  ? parsedPayload.amount.toString()
                  : '',
            },
          })
        )
        push('/transfer')
      }
    },
    [
      dispatch,
      enableRecovery,
      recoverySignedVAA,
      parsedPayloadTargetChain,
      parsedPayload,
      push,
    ]
  )

  const handleRecoverClick = useCallback(() => {
    handleRecoverClickBase(false)
  }, [handleRecoverClickBase])

  const handleRecoverWithRelayerClick = useCallback(() => {
    handleRecoverClickBase(true)
  }, [handleRecoverClickBase])

  return (
    <Container maxWidth="sm">
      <Card className={classes.mainCard}>
        <Alert severity="info" variant="outlined">
          If you have sent your tokens but have not redeemed them, you may paste
          in the Source Transaction ID (from Step 2) to resume your transfer.
        </Alert>
        <ChainSelect
          select
          placeholder="Select network"
          variant="outlined"
          disabled={!!recoverySignedVAA}
          value={recoverySourceChain}
          onChange={handleSourceChainChange}
          fullWidth
          margin="normal"
          chains={CHAINS}
        />
        <input
          className={classes.txInput}
          placeholder="Enter source transaction hash"
          disabled={
            !!recoverySignedVAA ||
            recoverySourceTxIsLoading ||
            !!walletConnectError
          }
          value={recoverySourceTx}
          onChange={handleSourceTxChange}
          // error={!!recoverySourceTxError || !!walletConnectError}
          // helperText={recoverySourceTxError || walletConnectError}
          // fullWidth
          // margin="normal"
        />
        <p className={classes.errorMessage}>{recoverySourceTxError || walletConnectError}</p>
        <RelayerRecovery
          parsedPayload={parsedPayload}
          signedVaa={recoverySignedVAA}
          onClick={handleRecoverWithRelayerClick}
        />
        <ButtonWithLoader
          onClick={handleRecoverClick}
          disabled={!enableRecovery}
          showLoader={recoverySourceTxIsLoading}
        >
          Recover
        </ButtonWithLoader>
        {/* <div className={classes.advancedContainer}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Advanced
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Box position="relative">
                  <TextField
                    variant="outlined"
                    label="Signed VAA (Hex)"
                    disabled={recoverySourceTxIsLoading}
                    value={recoverySignedVAA || ''}
                    onChange={handleSignedVAAChange}
                    fullWidth
                    margin="normal"
                  />
                  {recoverySourceTxIsLoading ? (
                    <Box
                      position="absolute"
                      style={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : null}
                </Box>
                <Box my={4}>
                  <Divider />
                </Box>
                <TextField
                  variant="outlined"
                  label="Emitter Chain"
                  disabled
                  value={recoveryParsedVAA?.emitter_chain || ''}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="outlined"
                  label="Emitter Address"
                  disabled
                  value={
                    (recoveryParsedVAA &&
                      hexToNativeString(
                        recoveryParsedVAA.emitter_address,
                        recoveryParsedVAA.emitter_chain
                      )) ||
                    ''
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="outlined"
                  label="Sequence"
                  disabled
                  value={recoveryParsedVAA?.sequence || ''}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="outlined"
                  label="Timestamp"
                  disabled
                  value={
                    (recoveryParsedVAA &&
                      new Date(
                        recoveryParsedVAA.timestamp * 1000
                      ).toLocaleString()) ||
                    ''
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="outlined"
                  label="Guardian Set"
                  disabled
                  value={recoveryParsedVAA?.guardian_set_index || ''}
                  fullWidth
                  margin="normal"
                />
                <Box my={4}>
                  <Divider />
                </Box>
                <TextField
                  variant="outlined"
                  label="Origin Chain"
                  disabled
                  value={parsedPayload?.originChain.toString() || ''}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="outlined"
                  label="Origin Token Address"
                  disabled
                  value={
                    (parsedPayload &&
                      hexToNativeAssetString(
                        parsedPayload.originAddress,
                        parsedPayload.originChain
                      )) ||
                    ''
                  }
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="outlined"
                  label="Target Chain"
                  disabled
                  value={parsedPayload?.targetChain.toString() || ''}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  variant="outlined"
                  label="Target Address"
                  disabled
                  value={
                    (parsedPayload &&
                      hexToNativeString(
                        parsedPayload.targetAddress,
                        parsedPayload.targetChain
                      )) ||
                    ''
                  }
                  fullWidth
                  margin="normal"
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </div> */}
      </Card>
    </Container>
  )
}
