import {
  AppBar,
  Container,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
} from '@material-ui/core'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { UnsupportedChainIdError } from '@web3-react/core'
import { Text } from 'rebass'
import styled from 'styled-components/macro'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import useActiveWeb3React from './hooks/useActiveWeb3React'
import { useEthereumProvider } from "./contexts/EthereumProviderContext";
import Recovery from './components/Recovery'
import Transfer from './components/Transfer'
import UnwrapNative from './components/UnwrapNative'
import Web3Status from './components/Web3Status'
import NetworkSelector from './components/NetworkSelector'
import { SUPPORTED_WALLETS } from './constants/wallet'
import { CHAIN_INFO } from './constants/chainInfo'
import { SupportedChainId } from './constants/chains'

const BalanceText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  @media all and (max-width: 767px) {
    display: none;
  }
`
const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  @media all and (max-width: 767px) {
    align-items: center;
  }
`

const AccountElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #191b1f;
  border-radius: 16px;
  white-space: nowrap;
  width: auto;
  height: 40px;

  :focus {
    border: 1px solid blue;
  }
`

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'transparent',
    marginBottom: theme.spacing(2),
    '& > .MuiToolbar-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: 'auto',
      width: '100%',
    },
  },
  logo: {
    height: 30,
    cursor: 'pointer',
    '&:hover': {
      transform: 'rotate(-5deg)',
    },
    [theme.breakpoints.down('md')]: {
      height: 24
    }
  },
  bg: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
    '& #background-radial-gradient': {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      pointerEvents: 'none',
      width: '200vw',
      height: '200vh',
      background:
        'radial-gradient(50% 50% at 50% 50%,#fc077d10 0,rgba(255,255,255,0) 100%)',
      transform: 'translate(-50vw,-100vh)',
      zIndex: 1,
    },
  },
  activeTab: {
    backgroundColor: 'rgb(33, 36, 41)',
    color: 'rgb(255, 255, 255)',
    fontWeight: 600,
  },
  betaBanner: {
    backgroundColor: 'rgba(0,0,0,0.75)',
    padding: theme.spacing(1, 0),
  },
}))

let balanceTimer = null

function App() {
  const classes = useStyles()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const { account, chainId, connector, activate } = useActiveWeb3React()
  const { connect } = useEthereumProvider();

  const [nativeBalance, setNativeBalance] = useState(0)

  const {
    nativeCurrency: { symbol: nativeCurrencySymbol },
  } = CHAIN_INFO[chainId ? chainId : SupportedChainId.MAINNET]

  useEffect(() => {
    const providerName = localStorage.getItem('providerName')
    if (providerName) {
      let _connector = null
      Object.keys(SUPPORTED_WALLETS).map((key) => {
        if (providerName === SUPPORTED_WALLETS[key].name) {
          return (_connector = SUPPORTED_WALLETS[key].connector)
        }
        return true
      })
      if (_connector) {
        activate(_connector, undefined, true)
          .then(async () => {
            const detectedProvider = await _connector.getProvider()
            connect(detectedProvider)
          })
          .catch((error) => {
            if (error instanceof UnsupportedChainIdError) {
              activate(_connector) // a little janky...can't use setError because the connector isn't set
            }
          })
      }
    }
  }, [])

  const handleTabChange = useCallback(
    (event, value) => {
      push(value)
    },
    [push]
  )

  const getBalance = async () => {
    if (connector && chainId && account) {
      const detectedProvider = await connector.getProvider()
      const provider = new ethers.providers.Web3Provider(
        // @ts-ignore
        detectedProvider,
        'any'
      )
      let balance = await provider.getBalance(account)
      balance = new BigNumber(balance._hex)
        .div(10 ** 18)
        .dp(3, 1)
        .toString()
      setNativeBalance(balance)
    }
  }

  useEffect(() => {
    if (connector && chainId && account) {
      getBalance()
      if (balanceTimer) {
        clearInterval(balanceTimer)
      }
      balanceTimer = setInterval(() => {
        getBalance()
      }, 2000)
      return () => clearInterval(balanceTimer)
    }
  }, [connector, chainId, account])

  return (
    <div className={classes.bg}>
      <AppBar
        position="static"
        color="inherit"
        className={classes.appBar}
        elevation={0}
      >
        <Toolbar>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a href="/">
              <img className={classes.logo} src="/logo.png" alt="logo" />
            </a>
          </div>
          <HeaderControls>
            <HeaderElement>
              <NetworkSelector />
            </HeaderElement>
            <HeaderElement>
              <AccountElement active={!!account}>
                {account && nativeBalance ? (
                  <BalanceText
                    style={{ flexShrink: 0, userSelect: 'none' }}
                    pl="0.75rem"
                    pr="0.5rem"
                    fontWeight={500}
                  >
                    {nativeBalance} {nativeCurrencySymbol}
                  </BalanceText>
                ) : null}
                <Web3Status />
              </AccountElement>
            </HeaderElement>
          </HeaderControls>
        </Toolbar>
      </AppBar>
      {['/transfer', '/redeem'].includes(pathname) ? (
        <Container maxWidth="sm" style={{ paddingBottom: 24 }}>
          <Tabs value={pathname} variant="fullWidth" onChange={handleTabChange}>
            <Tab
              className={pathname === '/transfer' ? classes.activeTab : ''}
              label="Bridge"
              value="/transfer"
            />
            <Tab
              className={pathname === '/redeem' ? classes.activeTab : ''}
              label="Redeem"
              value="/redeem"
              to="/redeem"
            />
          </Tabs>
        </Container>
      ) : null}
      <Switch>
        <Route exact path="/transfer">
          <Transfer />
        </Route>
        <Route exact path="/redeem">
          <Recovery />
        </Route>
        <Route exact path="/unwrap-native">
          <UnwrapNative />
        </Route>
        <Route>
          <Redirect to="/transfer" />
        </Route>
      </Switch>
      <div
        id="background-radial-gradient"
        style={{
          width: '200vw',
          height: '200vh',
          transform: 'translate(-50vw, -100vh)',
        }}
      />
    </div>
  )
}

export default App
