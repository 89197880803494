import { isEVMChain, uint8ArrayToHex } from '@certusone/wormhole-sdk'
import { arrayify, zeroPad } from '@ethersproject/bytes'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEthereumProvider } from '../contexts/EthereumProviderContext'
import {
  selectTransferTargetAsset,
  selectTransferTargetChain,
  selectTransferTargetParsedTokenAccount,
} from '../store/selectors'
import { setTargetAddressHex as setTransferTargetAddressHex } from '../store/transferSlice'

function useSyncTargetAddress(shouldFire: boolean, nft?: boolean) {
  const dispatch = useDispatch()
  const targetChain = useSelector(selectTransferTargetChain)
  const { signerAddress } = useEthereumProvider()
  const targetAsset = useSelector(selectTransferTargetAsset)
  const targetParsedTokenAccount = useSelector(
    selectTransferTargetParsedTokenAccount
  )
  const targetTokenAccountPublicKey = targetParsedTokenAccount?.publicKey
  const setTargetAddressHex = setTransferTargetAddressHex
  useEffect(() => {
    if (shouldFire) {
      let cancelled = false
      if (isEVMChain(targetChain) && signerAddress) {
        dispatch(
          setTargetAddressHex(
            uint8ArrayToHex(zeroPad(arrayify(signerAddress), 32))
          )
        )
      } else {
        dispatch(setTargetAddressHex(undefined))
      }
      return () => {
        cancelled = true
      }
    }
  }, [
    dispatch,
    shouldFire,
    targetChain,
    signerAddress,
    targetAsset,
    targetTokenAccountPublicKey,
    setTargetAddressHex,
  ])
}

export default useSyncTargetAddress
