import {
  ChainId,
  isEVMChain,
  redeemOnEth,
  redeemOnEthNative,
  uint8ArrayToHex,
} from '@certusone/wormhole-sdk'
import { Alert } from '@material-ui/lab'
import axios from 'axios'
import { Signer } from 'ethers'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEthereumProvider } from '../contexts/EthereumProviderContext'
import {
  selectTransferIsRedeeming,
  selectTransferTargetChain,
} from '../store/selectors'
import { setIsRedeeming, setRedeemTx } from '../store/transferSlice'
import {
  ACALA_RELAY_URL,
  getTokenBridgeAddressForChain,
} from '../utils/consts'
import parseError from '../utils/parseError'
import useTransferSignedVAA from './useTransferSignedVAA'

async function evm(
  dispatch: any,
  enqueueSnackbar: any,
  signer: Signer,
  signedVAA: Uint8Array,
  isNative: boolean,
  chainId: ChainId
) {
  dispatch(setIsRedeeming(true))
  try {
    // Klaytn requires specifying gasPrice
    const overrides = {}
    const receipt = isNative
      ? await redeemOnEthNative(
          getTokenBridgeAddressForChain(chainId),
          signer,
          signedVAA,
          overrides
        )
      : await redeemOnEth(
          getTokenBridgeAddressForChain(chainId),
          signer,
          signedVAA,
          overrides
        )
    dispatch(
      setRedeemTx({ id: receipt.transactionHash, block: receipt.blockNumber })
    )
    enqueueSnackbar(null, {
      content: <Alert severity="success">Transaction confirmed</Alert>,
    })
  } catch (e) {
    enqueueSnackbar(null, {
      content: <Alert severity="error">{parseError(e)}</Alert>,
    })
    dispatch(setIsRedeeming(false))
  }
}

export function useHandleRedeem() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const targetChain = useSelector(selectTransferTargetChain)
  const { signer } = useEthereumProvider()
  const signedVAA = useTransferSignedVAA()
  const isRedeeming = useSelector(selectTransferIsRedeeming)
  const handleRedeemClick = useCallback(() => {
    if (isEVMChain(targetChain) && !!signer && signedVAA) {
      evm(dispatch, enqueueSnackbar, signer, signedVAA, false, targetChain)
    }
  }, [
    dispatch,
    enqueueSnackbar,
    targetChain,
    signer,
    signedVAA,
  ])

  const handleRedeemNativeClick = useCallback(() => {
    if (isEVMChain(targetChain) && !!signer && signedVAA) {
      evm(dispatch, enqueueSnackbar, signer, signedVAA, true, targetChain)
    }
  }, [
    dispatch,
    enqueueSnackbar,
    targetChain,
    signer,
    signedVAA,
  ])

  const handleAcalaRelayerRedeemClick = useCallback(async () => {
    if (!signedVAA) return

    dispatch(setIsRedeeming(true))

    try {
      const res = await axios.post(ACALA_RELAY_URL, {
        targetChain,
        signedVAA: uint8ArrayToHex(signedVAA),
      })

      dispatch(
        setRedeemTx({
          id: res.data.transactionHash,
          block: res.data.blockNumber,
        })
      )
      enqueueSnackbar(null, {
        content: <Alert severity="success">Transaction confirmed</Alert>,
      })
    } catch (e) {
      enqueueSnackbar(null, {
        content: <Alert severity="error">{parseError(e)}</Alert>,
      })
      dispatch(setIsRedeeming(false))
    }
  }, [targetChain, signedVAA, enqueueSnackbar, dispatch])

  return useMemo(
    () => ({
      handleNativeClick: handleRedeemNativeClick,
      handleClick: handleRedeemClick,
      handleAcalaRelayerRedeemClick,
      disabled: !!isRedeeming,
      showLoader: !!isRedeeming,
    }),
    [
      handleRedeemClick,
      isRedeeming,
      handleRedeemNativeClick,
      handleAcalaRelayerRedeemClick,
    ]
  )
}
