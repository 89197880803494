import { Box } from 'rebass/styled-components'
import styled from 'styled-components/macro'

const Card = styled(Box)<{ width?: string; padding?: string; border?: string; $borderRadius?: string }>`
  width: ${({ width }) => width ?? '100%'};
  padding: ${({ padding }) => padding ?? '1rem'};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? '16px'};
  border: ${({ border }) => border};
`
export default Card

export const LightCard = styled(Card)`
  border: 1px solid #2C2F36;
  background-color: #212429;
`

export const LightGreyCard = styled(Card)`
  background-color: #2C2F36;
`

export const GreyCard = styled(Card)`
  background-color: #40444F;
`

export const DarkGreyCard = styled(Card)`
  background-color: #2C2F36;
`

export const DarkCard = styled(Card)`
  background-color: #191B1F;
`

export const OutlineCard = styled(Card)`
  border: 1px solid #40444F;
`

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: #F3B71E;
  font-weight: 500;
`

export const BlueCard = styled(Card)`
  background-color: #153d6f70;
  color: #5199FF;
  border-radius: 12px;
`
