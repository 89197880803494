import {
  ChainId,
  getEmitterAddressEth,
  isEVMChain,
  parseSequenceFromLogEth,
  transferFromEth,
  transferFromEthNative,
  uint8ArrayToHex,
} from '@certusone/wormhole-sdk'
import { Alert } from '@material-ui/lab'
import { Signer } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEthereumProvider } from '../contexts/EthereumProviderContext'
import {
  selectTransferAmount,
  selectTransferIsSendComplete,
  selectTransferIsSending,
  selectTransferIsTargetComplete,
  selectTransferRelayerFee,
  selectTransferSourceAsset,
  selectTransferSourceChain,
  selectTransferSourceParsedTokenAccount,
  selectTransferTargetChain,
} from '../store/selectors'
import {
  setIsSending,
  setSignedVAAHex,
  setTransferTx,
} from '../store/transferSlice'
import {
  getBridgeAddressForChain,
  getTokenBridgeAddressForChain,
} from '../utils/consts'
import { getSignedVAAWithRetry } from '../utils/getSignedVAAWithRetry'
import parseError from '../utils/parseError'
import useTransferTargetAddressHex from './useTransferTargetAddress'

async function evm(
  dispatch: any,
  enqueueSnackbar: any,
  signer: Signer,
  tokenAddress: string,
  decimals: number,
  amount: string,
  recipientChain: ChainId,
  recipientAddress: Uint8Array,
  isNative: boolean,
  chainId: ChainId,
  relayerFee?: string
) {
  dispatch(setIsSending(true))
  try {
    const baseAmountParsed = parseUnits(amount, decimals)
    const feeParsed = parseUnits(relayerFee || '0', decimals)
    const transferAmountParsed = baseAmountParsed.add(feeParsed)
    console.log(
      'base',
      baseAmountParsed,
      'fee',
      feeParsed,
      'total',
      transferAmountParsed
    )
    // Klaytn requires specifying gasPrice
    const overrides = {}
    const receipt = isNative
      ? await transferFromEthNative(
          getTokenBridgeAddressForChain(chainId),
          signer,
          transferAmountParsed,
          recipientChain,
          recipientAddress,
          feeParsed,
          overrides
        )
      : await transferFromEth(
          getTokenBridgeAddressForChain(chainId),
          signer,
          tokenAddress,
          transferAmountParsed,
          recipientChain,
          recipientAddress,
          feeParsed,
          overrides
        )
    dispatch(
      setTransferTx({ id: receipt.transactionHash, block: receipt.blockNumber })
    )
    enqueueSnackbar(null, {
      content: <Alert severity="success">Transaction confirmed</Alert>,
    })
    const sequence = parseSequenceFromLogEth(
      receipt,
      getBridgeAddressForChain(chainId)
    )
    const emitterAddress = getEmitterAddressEth(
      getTokenBridgeAddressForChain(chainId)
    )
    enqueueSnackbar(null, {
      content: <Alert severity="info">Fetching VAA</Alert>,
    })
    const { vaaBytes } = await getSignedVAAWithRetry(
      chainId,
      emitterAddress,
      sequence.toString()
    )
    dispatch(setSignedVAAHex(uint8ArrayToHex(vaaBytes)))
    enqueueSnackbar(null, {
      content: <Alert severity="success">Fetched Signed VAA</Alert>,
    })
  } catch (e) {
    console.error(e)
    enqueueSnackbar(null, {
      content: <Alert severity="error">{parseError(e)}</Alert>,
    })
    dispatch(setIsSending(false))
  }
}

export function useHandleTransfer() {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const sourceChain = useSelector(selectTransferSourceChain)
  const sourceAsset = useSelector(selectTransferSourceAsset)
  const amount = useSelector(selectTransferAmount)
  const targetChain = useSelector(selectTransferTargetChain)
  const targetAddress = useTransferTargetAddressHex()
  const isTargetComplete = useSelector(selectTransferIsTargetComplete)
  const isSending = useSelector(selectTransferIsSending)
  const isSendComplete = useSelector(selectTransferIsSendComplete)
  const { signer } = useEthereumProvider()
  const sourceParsedTokenAccount = useSelector(
    selectTransferSourceParsedTokenAccount
  )
  const relayerFee = useSelector(selectTransferRelayerFee)

  const decimals = sourceParsedTokenAccount?.decimals
  const isNative = sourceParsedTokenAccount?.isNativeAsset || false
  const disabled = !isTargetComplete || isSending || isSendComplete

  const handleTransferClick = useCallback(() => {
    // TODO: we should separate state for transaction vs fetching vaa
    if (
      isEVMChain(sourceChain) &&
      !!signer &&
      !!sourceAsset &&
      decimals !== undefined &&
      !!targetAddress
    ) {
      evm(
        dispatch,
        enqueueSnackbar,
        signer,
        sourceAsset,
        decimals,
        amount,
        targetChain,
        targetAddress,
        isNative,
        sourceChain,
        relayerFee
      )
    }
  }, [
    dispatch,
    enqueueSnackbar,
    sourceChain,
    signer,
    relayerFee,
    sourceAsset,
    amount,
    decimals,
    targetChain,
    targetAddress,
    isNative,
  ])
  return useMemo(
    () => ({
      handleClick: handleTransferClick,
      disabled,
      showLoader: isSending,
    }),
    [handleTransferClick, disabled, isSending]
  )
}
